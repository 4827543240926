import React, { FC, useState } from "react"
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import Typography from "@material-ui/core/Typography"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import { DashBoardIcon } from "../assets"
import Button from "@material-ui/core/Button"
import Box from "@material-ui/core/Box"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            flexBasis: "33.33%",
            flexShrink: 0,
        },
        secondaryHeading: {
            fontFamily: "Source Sans Pro",
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "16px",
            lineHeight: "24px",
            color: "#354530",
        },
        listItemText: {
            fontFamily: "Source Sans Pro",
            fontSize: "16px!important",
            lineHeight: "24px!important",
            fontWeight: 400,
            paddingTop: "0px",
            paddingBottom: "0px",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
        },
        details: {
            paddingLeft: "18px",
            paddingTop: "0px",
            paddingBottom: "0px",
            display: "flex",
            flexDirection: "column",
        },
        paddingTop: {
            paddingTop: "0px",
        },
        accordionSummary: {
            minHeight: "0px",
            paddingBottom: "0px",
        },
    })
)

const CustomAccordion: FC<CustomAccordionType> = props => {
    const {
        icon,
        setAccordionStates,
        index,
        selected,
        accordionStates,
        setSelected
    } = props
    const classes = useStyles()

    const handleChange = () => {
        setAccordionStates({
            ...accordionStates,
            [index]: {
                ...accordionStates[index],
                ['expanded']: accordionStates[index].expanded ? false : true
            }
        })
    }

    const handleButtonClick = (i) => {
        setSelected(`${index}-${i}`)
    }

    return (
        <div className={classes.root}>
            <Accordion expanded={accordionStates[index].expanded} onChange={() => handleChange()}>
                <AccordionSummary
                    className={classes.accordionSummary}
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <DashBoardIcon />
                    <Typography className={classes.secondaryHeading}>
                        {accordionStates[index].title || "Tableau de bord"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>
                    <div className={classes.details}>
                        {accordionStates[index].items.map((item, i) => {
                            return (
                                <div>
                                    <Button
                                        key={i}
                                        onClick={() => {
                                            item.onClick && item.onClick()
                                            handleButtonClick(i);
                                        }}
                                        className={classes.listItemText}
                                        style={{ color: selected == `${index}-${i}` ? "#354530" : "#858C94" }}
                                        color="secondary"
                                    >
                                        {item.text}
                                    </Button>
                                    <Box paddingTop={2} />
                                </div>
                            )
                        })}
                    </div>
                </AccordionDetails>
            </Accordion>
        </div>
    )
}

type CustomAccordionType = {
    icon?: JSX.Element;
    index?: number;
    setAccordionStates?: any;
    selected?: string;
    accordionStates?: any;
    setSelected?: any;
}

export default CustomAccordion
