import React, { useState } from "react"
import { DataGrid, GridRowId } from "@material-ui/data-grid"
import "./style.scss"
import { useHistory } from "react-router-dom";

const Table = ({ data }) => {
  const [selectedModels, setSelectedModels] = useState<GridRowId[]>([])
  const history = useHistory();

  const status = params => {
    return (
      <div
        className="status"
        style={{ backgroundColor: params.value ? "#EDF9F0" : "#EDF9F0" }}
      >
        <div style={{ color: params.value ? "#287D3C" : "red" }}>
          {params.value ? "Actif" : "Deactif"}
        </div>
      </div>
    )
  }

  const columns = [
    // {
    //   field: "id",
    //   flex: 0.8,
    //   headerName: "ID",
    //   cellClassName: "num-id",
    //   renderHeader: params => (
    //     <div className="header">{params.colDef.headerName}</div>
    //   ),
    //   renderCell: params => params.value.slice(-10),
    // },
    {
      field: "productName",
      cellClassName: "num-id",
      headerName: "Nom et code",
      flex: 1,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "category",
      cellClassName: "num-id",
      headerName: "Catégorie",
      flex: 1,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "image",
      headerName: "Image",
      flex: 1,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
      renderCell: params => <img src={params.value}></img>,
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
      renderCell: status,
    },
  ]

  return (
    <div style={{ height: "80vh" }}>
      <DataGrid
        className="model-table"
        rowHeight={90}
        pageSize={10}
        columns={columns}
        rows={data}
        onSelectionModelChange={(newSelection: any) => {
          // setSelectedModels(newSelection.selectionModel)
          history.push(`/product/${newSelection.selectionModel['0']}`);
        }}
        // selectionModel={selectedModels}
        // checkboxSelection
      />
    </div>
  )
}
export default Table
