import React, { FC, useContext } from 'react';
import { makeStyles, fade } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import { IconButton, Button } from '@material-ui/core';
import MonZayaLogo from '../assets/icons_2k/logo.svg';
import MonZayaMobileLogo from '../assets/icons_2k/logo-mobile.svg';
import AppBar from '@material-ui/core/AppBar';
import { useFirebase, useUser } from '../firebase/firebase';
import { useHistory } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { MenuContext } from '../contexts/MenuContext';
import { EditProductContext } from '../contexts/EditProductContext';
import { WindowResolutionContext } from '../contexts/WindowResolutionContext';


const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: 'white',
        zIndex: theme.zIndex.drawer + 1,
    },
    title: {
        flexGrow: 1,
        color: 'black',
        paddingLeft: '15px',
        fontFamily: 'Source Sans Pro',
        fontSize: '16px',
        fontWeight: 'normal',
        fontStyle: 'normal',
        lineHeight: '24px'
    },
    divider: {
        height: '45px',
        alignSelf: 'center',
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
}));


const CustomAppBar: FC<AppBarType> = (props) => {
    const { text, logo } = props;
    const history = useHistory();
    const classes = useStyles();
    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
    const { user, loading } = useUser();
    const { auth } = useFirebase();
    const params = new URLSearchParams(window.location.search);
    const goBackUrl = params.get('back');
    const { isHiddenAdminText } = useContext(MenuContext);
    const { isAdmin } = useContext(EditProductContext);
    const { isMobile } = useContext(WindowResolutionContext);


    const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const logout = async () => {
        await auth.signOut();
        history.push('/login');
    }

    const goBackToShopify = () => {
        window.location.href = goBackUrl;
    }

    const onLogoClick = () => {
        if (isAdmin) {
            history.push('/');
        }
    }

    return (
        <AppBar position="fixed" className={classes.appBar} style={{ zIndex: 20000000 }}>
            <Toolbar style={isMobile ? ({ height: 43, minHeight: 43 }) : ({})}>
                <IconButton onClick={onLogoClick} edge="start" color="inherit" aria-label="menu">
                    <img src={isMobile ? MonZayaMobileLogo : MonZayaLogo} style={{ height: isMobile ? 33 : 55 }} />
                </IconButton>
                {user && !isHiddenAdminText && (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Divider orientation="vertical" className={classes.divider} />
                        <Typography variant="h6" className={classes.title}>
                            {text || "Panneau D'Administration"}
                        </Typography>
                    </div>
                )}
                <div style={{ flex: 1 }}></div>
                <div className={classes.sectionDesktop}>

                </div>
                {!loading && user && <Button onClick={logout}>Déconnexion</Button>}
                {!loading && !user && <Button onClick={goBackToShopify} variant="outlined" color="secondary" startIcon={<ExitToAppIcon />}>Quitter</Button>}
            </Toolbar>
        </AppBar>

    );
}

type AppBarType = {
    text: string,
    logo: any
}

export default CustomAppBar