import { Box } from '@material-ui/core';
import React, { useContext } from 'react';
import { EditProductContext } from '../../contexts/EditProductContext';
import undoLeft from './../../assets/icons_2k/undo.svg';
import undoRight from './../../assets/icons_2k/redo.svg';

const UndoRedo = ({ isMobile = false }) => {
    const { undo, redo, canRedo, canUndo, undoRedoHistory, undoRedoIndex } = useContext(EditProductContext);

    if (isMobile) {
        return (
            <div style={{ position: 'fixed', top: 6, zIndex: 999999999, right: 141, display: 'flex' }}>
                <div style={{ cursor: 'pointer', border: 'none', outline: 'none', borderRight: !isMobile ? '1px solid #DADEE3' : 'none' }} onClick={() => undo()}>
                    <img src={undoLeft} alt="prev" style={{ transform: 'scale(.7)', opacity: canUndo ? 1 : 0.6 }} />
                </div>
                <div style={{ cursor: 'pointer', border: 'none', outline: 'none' }} onClick={() => redo()}>
                    <img src={undoRight} alt="next" style={{ transform: 'scale(.7)', opacity: canRedo ? 1 : 0.6 }} />
                </div>
            </div>
        )
    }

    return (
        <Box display='flex' className='right-header-container' style={{ borderBottom: !isMobile ? '1px solid #DADEE3' : 'none' }}>
            <button style={{ cursor: 'pointer', border: 'none', outline: 'none', borderRight: !isMobile ? '1px solid #DADEE3' : 'none' }} onClick={() => undo()}>
                <img src={undoLeft} alt="prev" style={{ transform: 'scale(.7)', opacity: canUndo ? 1 : 0.6 }} />
            </button>
            <button style={{ cursor: 'pointer', border: 'none', outline: 'none' }} onClick={() => redo()}>
                <img src={undoRight} alt="next" style={{ transform: 'scale(.7)', opacity: canRedo ? 1 : 0.6 }} />
            </button>
        </Box>
    )
}

export default UndoRedo;
