import React, { useCallback, useEffect, useState } from 'react';
import './style.scss';
import TextField from '@material-ui/core/TextField';
import * as _ from 'lodash';


const Input = (props) => {
    const {value, setValue, placeholder, title, maxCharacter = 0, style = {}, disabled = false} = props;
    const throttled = useCallback(_.debounce(d => setValue(d), 10), []);

    const [localValue, setLocalValue] = useState<string>('');

    // const length = value == '' || !value ? 0 : value.length;

    useEffect(() => {
        setLocalValue(value);
    }, [value]);

    const onValueChange = (e) => {
        setLocalValue(e.target.value);
        throttled(e.target.value);
        // setValue(e.target.value);
    }

    return (<>
        <div className='editor'>
                {title && <p>{title} *</p>}
                <TextField 
                    value={localValue} 
                    onChange={onValueChange} 
                    multiline 
                    placeholder={placeholder}
                    inputProps={maxCharacter > 0 ? { maxLength: maxCharacter } : {}}
                    disabled={disabled}
                >
                        {/* style={{...style}} */}
                </TextField>
                {maxCharacter > 0 && (
                    <div style={{ color: '#354530' }}>{length}/{maxCharacter}</div>
                )}
        </div>
    </>)
}

export default Input;