import _ from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Input from '../../../components/Input/Input';
import { EditProductContext } from '../../../contexts/EditProductContext';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import './UserCustomizeText.scss';
import { UserCustomizeTextContext } from '../../../contexts/UserCustomizeTextContext';
import TextModifierSettings from '../TextModifierSettings';
import SideSectionHeader from '../SideSectionHeader';
import { WindowResolutionContext } from '../../../contexts/WindowResolutionContext';
import { Def, Deselect } from '../../../components/Customize/Customize';
import { KOOL_GREEN, TEXT_SELECTION_PADDING } from '../../../const';
import { fabric } from 'fabric'
import Hammer from 'hammerjs'
import { MainContext } from '../../../contexts/MainContext';

const UserCustomizeText = () => {
    const [defaultZoom, setDefaultZoom] = useState(1)
    const { isMobile } = useContext(WindowResolutionContext);

    const { models, selectedModelId, userChangeText, selectedBgIndex, userChangeTextById, userRemoveText, userRemoveTextLS, isCanvasLoaded, userAddText } = useContext(EditProductContext);

    const selectedModelIdRef = useRef(selectedModelId);
    const { texts = [], textPlaceHolders = [], textTitles = [], textCharacterLimits = [] } = _.get(models, [selectedModelId], {});
    // const { texts, changeUserText } = useContext(UserCustomizeTextContext);
    const [maxTextLines, setMaxTextLines] = useState(-1);
    const [maxTextLinesByModels, setMaxTextLinesByModels] = useState<any>({})

    const [textSelected, setTextSelected] = useState(false)

    const clickedObject = useRef<any>(null)
    const lastTapTime = useRef<any>(null)

    const [addedEvents, setAddedEvents] = useState(false)

    const ghostInput = useRef(null)
    const [ghostInputValue, setGhostInputValue] = useState('')



    const { width: SCREEN_WIDTH } = useContext(WindowResolutionContext)

    const {
        canvasRef
    } = useContext(MainContext)

    useEffect(() => {
        selectedModelIdRef.current = selectedModelId; // Keep the ref updated with the latest value
    }, [selectedModelId]);

    useEffect(() => {
        if (!isCanvasLoaded) {
            return
        }
        hideBlinkingCursor()
        objectCleared()
    }, [selectedModelId, isCanvasLoaded, selectedBgIndex])

    function isNoSelectableElementAtTouchPosition(event) {
        const pointer = Def.canvas.getPointer(event.changedTouches[0]);
        const objects = Def.canvas.getObjects().filter(obj => obj.selectable && obj.evented !== false && obj.opacity !== 0 && !obj.id.startsWith('material'));

        for (let i = 0; i < objects.length; i++) {
            const obj = objects[i];
            const boundingRect = obj.getBoundingRect(true, true);
            if (pointer.x >= boundingRect.left &&
                pointer.x <= boundingRect.left + boundingRect.width &&
                pointer.y >= boundingRect.top &&
                pointer.y <= boundingRect.top + boundingRect.height) {
                return false; // Selectable element found at touch position
            }
        }

        return true; // No selectable element found at touch position
    }

    function handleTouchEnd(event) {
        var currentTime = new Date().getTime();
        var dragTime = currentTime - lastTapTime.current;

        if (dragTime < 50 && isNoSelectableElementAtTouchPosition(event)) {
            // Def.canvas.deselectAll()
            Deselect()
            objectCleared()
            hideBlinkingCursor()

            localStorage.removeItem('lastSelectedItemId')
            // Your logic here when there's no selectable element underneath
        }
    }

    function handleTap(event) {
        // const lastSelectedItemId = localStorage.getItem('lastSelectedItemId')

        // if (lastSelectedItemId) {
        //     const lastSelectedObject = Def.canvas.getObjects().find(s => s.id === lastSelectedItemId)
        //     Def.canvas.setActiveObject(lastSelectedObject)
        // }



        var currentTime = new Date().getTime();
        var tapTime = currentTime - lastTapTime.current;

        var touch = event.touches[0];

        localStorage.setItem('touchesLength', `${event.touches.length}`)

        var target = Def.canvas.findTarget(touch);



        if (target && target === clickedObject.current) {

            // If tap time is within threshold, consider it a double tap
            if (tapTime < 300) {
                // Double tap logic here

                if (target.id && !target.id.includes('text')) {
                    return
                }

                if (isAndroid()) {
                    localStorage.setItem('blockedZoomAt', `${Date.now()}`)
                    // const currentZoom = Def.canvas.getZoom();
                    // const currentViewportTransform = [...Def.canvas.viewportTransform];

                    // let i = 0
                    // let iid = setInterval(() => {
                    //     i += 10
                    //     if (i >= 1000) {
                    //         clearInterval(iid)
                    //     }
                    //     Def.canvas.setZoom(currentZoom);
                    //     Def.canvas.setViewportTransform(currentViewportTransform);
                    //     Def.canvas.renderAll();
                    // }, 10);
                }

                // if (target && isMobile) {
                //     if (ghostInput.current == document.activeElement) {
                prepForEditing(target)
                changeBlinkingCursorPos(target)
                // }
                // }
            }
        }

        lastTapTime.current = currentTime;
        clickedObject.current = target;

        event.preventDefault();
    }

    useEffect(() => {
        if (!_.isEmpty(models)) {
            localStorage.setItem('models', JSON.stringify(models))
        }
    }, [models])

    function isAndroid() {
        return /android/i.test(navigator.userAgent);
    }

    useEffect(() => {
        if (maxTextLinesByModels[selectedModelId] == null) {
            setMaxTextLinesByModels(prev => ({ ...prev, [selectedModelId]: texts.length }));
        }
        if (isCanvasLoaded && !addedEvents) {

            Def.canvas.selection = false



            Def.canvas.on('selection:cleared', () => {
                setTextSelected(false)

                checkDeleteText()

                if (ghostInput.current) {
                    ghostInput.current.blur();
                    hideBlinkingCursor()
                    console.log('blur')
                }
            })


            Def.canvas.on('selection:created', objectSelected)
            Def.canvas.on('selection:updated', e => {
                const activeObj = Def.canvas.getActiveObject()

                checkDeleteText()
                objectSelected(e)
            })
            Def.canvas.on('object:scaling', objectScalingOrMoving)
            Def.canvas.on('object:moving', objectScalingOrMoving)

            if (isMobile) {
                Def.canvas.upperCanvasEl.addEventListener('touchstart', handleTap, { passive: false });
                Def.canvas.upperCanvasEl.addEventListener('touchend', handleTouchEnd, { passive: false });
            }

            setAddedEvents(true)

        }
    }, [isCanvasLoaded, addedEvents, models, selectedModelId]);

    useEffect(() => {
        if (isCanvasLoaded) {
            initBlinkingCursor()

            if (ghostInput.current) {
                ghostInput.current.blur()
            }
            localStorage.setItem('zzz', `${Def.canvas.getZoom()}`)

            var hammertime = new Hammer(canvasRef.current, {});
            hammertime.get('pinch').set({ enable: true });
            hammertime.on('pinchstart', function (ev) {
                localStorage.setItem('isPinching', 'true')
                // localStorage.removeItem("zoomedToText")

                localStorage.setItem('lastZoom', `${Def.canvas.getZoom()}`)
                localStorage.setItem('pinchCenterX', `${ev.center.x + Def.canvas.viewportTransform[4] * Def.canvas.getZoom()}`)
                localStorage.setItem('pinchCenterY', `${ev.center.y + Def.canvas.viewportTransform[5] * Def.canvas.getZoom()}`)
            })
            hammertime.on('pinchend', function (ev) {
                localStorage.removeItem('isPinching')

                const currentZoom = Def.canvas.getZoom();
                const minZoom = localStorage.getItem("minZoom")
                if (currentZoom < minZoom) {
                    animateZoomBack(minZoom);
                }
            })
            hammertime.on('pinchmove', function (ev) {
                console.log(ev)
                // hammertime.on('pan', function (ev) {
                let scale = ev.scale
                // if (scale !== 1) {
                //     scale = 1 + (scale - 1) / 20
                // }

                const lastZoom = Number(localStorage.getItem('lastZoom'))
                let zoom = Math.min(2, lastZoom * scale)
                if (zoom < .1) {
                    zoom = .1
                }
                // zoom = Math.max(Number(localStorage.getItem("minZoom")), zoom)

                const zzz = Number(localStorage.getItem('zzz'))
                const pinchCenter = {
                    x: Number(localStorage.getItem('pinchCenterX')),
                    y: Number(localStorage.getItem('pinchCenterY')),
                }
                var point = new fabric.Point(pinchCenter.x, pinchCenter.y);

                const newP = fabric.util.transformPoint(point, Def.canvas.viewportTransform)

                // Def.canvas.viewportTransform[0] = zoom
                // Def.canvas.viewportTransform[3] = zoom

                // Get the dimensions of the canvas
                var canvasWidth = Def.canvas.getWidth();
                var canvasHeight = Def.canvas.getHeight();


                const point1 = new fabric.Point(ev.center.x - (SCREEN_WIDTH - canvasWidth) * .5, ev.center.y - 120);
                // const point1 = new fabric.Point(canvasWidth / 2, canvasHeight / 2);
                // if (Number((localStorage.getItem('blockedZoomAt') || `${Date.now()}`)) + 1000 < Date.now()) {
                Def.canvas.zoomToPoint(point1, zoom);
                // }

                const activeObj = Def.canvas.getActiveObject()
                if (activeObj && activeObj.id.includes('text')) {
                    activeObj.set('padding', TEXT_SELECTION_PADDING * zoom);
                }

            });
        }
    }, [isCanvasLoaded])

    const isAnimatingZoom = useRef(false);

    function animateZoomBack(targetZoom) {
        isAnimatingZoom.current = true;
        const currentZoom = Def.canvas.getZoom();
        const zoomSteps = 10;
        const stepSize = (targetZoom - currentZoom) / zoomSteps;
        let stepCount = 0;

        function zoomStep() {
            if (stepCount < zoomSteps) {
                const newZoom = currentZoom + stepSize * (stepCount + 1);
                const canvasWidth = Def.canvas.getWidth();
                const canvasHeight = Def.canvas.getHeight();
                const point1 = new fabric.Point(canvasWidth / 2, canvasHeight / 2); // Center zoom on canvas

                // if (Number((localStorage.getItem('blockedZoomAt') || `${Date.now()}`)) + 1000 < Date.now()) {
                Def.canvas.zoomToPoint(point1, newZoom);
                // }
                stepCount++;
                requestAnimationFrame(zoomStep);
            } else {
                isAnimatingZoom.current = false;
                localStorage.setItem('lastZoom', targetZoom); // Update the lastZoom to the new value
            }
        }

        zoomStep();
    }

    function initBlinkingCursor() {
        const objs = Def.canvas.getObjects()
        let cursor = objs.find(obj => obj.id === 'blinking-cursor')

        if (!cursor) {
            cursor = new fabric.Rect({
                width: 0, // Cursor width
                fill: 'black', // Cursor color
                selectable: false, // Cursor should not be selectable
                evented: false,
            })
            cursor.id = 'blinking-cursor'
            Def.canvas.add(cursor)
        }

        cursor.width = 0

        let blinkinCursorIntervalId: any = localStorage.getItem('blinkinCursorIntervalId')
        clearInterval(blinkinCursorIntervalId)
        blinkinCursorIntervalId = setInterval(() => {
            cursor.visible = !cursor.visible
            Def.canvas.renderAll()
        }, 500)
        setInterval(() => {
            changeBlinkingCursorPos(Def.canvas.getActiveObject())
        }, 50)
        localStorage.setItem('blinkinCursorIntervalId', `${blinkinCursorIntervalId}`)
    }

    function changeBlinkingCursorPos(text, oldValue = '', newValue = '') {
        if (ghostInput.current != document.activeElement) {
            return
        }
        const objs = Def.canvas.getObjects()
        let cursor = objs.find(obj => obj.id === 'blinking-cursor')

        if (!text) {
            cursor.width = 0
            return
        }

        cursor.width = 2



        const lastLetter: any = _.last(text._objects)

        const lastLetterHeight = lastLetter.height * text.scaleY * lastLetter.scaleY//Math.abs(lastLetter.aCoords.tr.y - lastLetter.aCoords.br.y)
        cursor.height = lastLetterHeight

        const objectHeight = text.height * text.scaleY;

        // Get the stroke width (assuming it's the same on all sides)
        const strokeWidth = text.strokeWidth || 0;

        const zoom = Def.canvas.getZoom()
        // Get the selection padding
        const selectionPadding = text.padding || 0;


        // Calculate the total height including the selection border
        const totalHeight = (objectHeight + (strokeWidth + selectionPadding) * 2);

        if (text.alignment === 'left') {
            cursor.left = text.left + lastLetter.aCoords.tr.x * text.scaleX - lastLetter.width * .5 * text.scaleX//text.left + lastLetter.left + lastLetter['width'] * .5 * text.scaleX * lastLetter.scaleX
            cursor.top = text.top + objectHeight * .5 - lastLetterHeight * 1
        }
        if (text.alignment === 'center') {
            const sameLineLetters: any[] = text._objects.filter(s => s.top === lastLetter.top)
            const sameLineWidth = text.scaleX * (
                _.last(sameLineLetters).aCoords.tr.x - _.first(sameLineLetters).aCoords.tl.x
            );
            cursor.left = -sameLineWidth * .5 + text.width * .5 * text.scaleX + text.left + lastLetter.aCoords.tr.x * text.scaleX
            // cursor.top = text.top + lastLetter.top - lastLetterHeight * .5
            cursor.top = text.top + objectHeight * .5 - lastLetterHeight * 1
        }
        if (text.alignment === 'right') {
            cursor.left = text.left + text.width * .5 * text.scaleX
            cursor.top = text.top + objectHeight * .5 - lastLetterHeight * 1
        }
        Def.canvas.renderAll()
    }

    function hideBlinkingCursor() {
        const objs = Def.canvas.getObjects()
        let cursor = objs.find(obj => obj.id === 'blinking-cursor')
        if (cursor) {
            cursor.width = 0
        }
    }

    const objectScalingOrMoving = e => {
        if (e.target.id && !e.target.id.includes('text')) {
            return;
        }
        changeBlinkingCursorPos(e.target)
    }

    const objectSelected = e => {
        localStorage.setItem('lastSelectedItemId', e.target.id)
        if (e.target.id && !e.target.id.includes('text')) {
            return;
        }


        setTextSelected(true)
        if (isMobile) {
            // console.log('text slected keyboar')
            // e.target.set('padding', TEXT_SELECTION_PADDING * Def.canvas.getZoom());
            // } else {
            // prepForEditing(e.target)
            // activateSelectedTextInput()
        } else {
            activateSelectedTextInput()
        }
    }

    const checkDeleteText = () => {
        console.log('delete text')
        try {
            setTimeout(() => {
                const texts = JSON.parse(localStorage.getItem('textsVisible').toString())
                const textIndex = texts.findIndex(s => s === '' || s === ' ')
                console.log(texts, textIndex)
                if (textIndex !== -1) {
                    userRemoveTextLS(selectedModelIdRef.current, textIndex)
                }
            }, 100)
        } catch (e) {

        }
    }

    const prepForEditing = (target) => {
        activateSelectedTextInputMobile()
        changeBlinkingCursorPos(target)
    }

    const zoomToText = (target) => {
        // localStorage.setItem("zoomedToText", "true")
        // if (isMobile) {
        //     // const zoom = defaultZoom * 2
        //     const zoom = Math.min((Def.canvas.getWidth() - 69) / (target.width * target.scaleX), (Def.canvas.getHeight() - 141) / (target.height * target.scaleY))
        //     const vpw = Def.canvas.width / zoom
        //     const vph = Def.canvas.height / zoom
        //     const x = (target.left - vpw / 2)
        //     const y = (target.top - vph / 2)

        //     setTimeout(() => {
        //         // Def.canvas.setZoom(1)
        //         // Def.canvas.absolutePan({ x: x, y: y })
        //         // Def.canvas.setZoom(zoom)

        //         // target.set('padding', TEXT_SELECTION_PADDING * zoom);
        //         activateSelectedTextInputMobile()
        //     }, 300)
        // }

        // setTextSelected(true)
        // activateSelectedTextInput()
    }

    const objectCleared = () => {
        if (ghostInput.current) {
            ghostInput.current.blur();
        }

        if (isAndroid()) {
            const currentZoom = Def.canvas.getZoom();
            const currentViewportTransform = [...Def.canvas.viewportTransform];

            requestAnimationFrame(() => {
                Def.canvas.setZoom(currentZoom);
                Def.canvas.setViewportTransform(currentViewportTransform);
                Def.canvas.renderAll();
            });
        }

        setTextSelected(false)
    }

    const [selectedTextId, setSelectedTextId] = useState('')
    const [selectedTextIndex, setSelectedTextIndex] = useState(-1)

    const activateSelectedTextInput = () => {
        const selectedModelId = localStorage.getItem('selectedModelId') || null
        const models = JSON.parse(localStorage.getItem('models'))
        if (!_.get(models, [selectedModelId, 'textIds'])) {
            return
        }
        const object = Def.canvas.getActiveObject()
        if (!object) {
            return
        }
        const textId = object.id
        const textIndex = models[selectedModelId]['textIds'].findIndex(s => s === textId)
        // setSelectedTextIndex(textIndex)
        setSelectedTextId(textId)
    }

    //////
    //////
    //////
    //////  anhaaraarai!
    //////  anhaaraarai!
    //////  anhaaraarai!
    //////
    //////  "models" object react-aas bolood bainga update hiigdehgui 
    //////  anhnii utgaaraa bgad bga shu
    //////
    //////

    const activateSelectedTextInputMobile = () => {
        const selectedModelId = localStorage.getItem('selectedModelId') || null
        const models = JSON.parse(localStorage.getItem('models'))
        if (!_.get(models, [selectedModelId, 'textIds'])) {
            return
        }
        const object = Def.canvas.getActiveObject()
        if (!object.id.includes('text')) {
            return
        }

        const texts = JSON.parse(localStorage.getItem('textsVisible').toString())
        const textIndex = texts.findIndex(s => s === object.text)
        localStorage.setItem('lastSelectedTextIndex', `${textIndex}`)

        const textId = object.id
        setGhostInputValue(object.text)
        setSelectedTextId(textId)

        ghostInput.current.focus()

        setTimeout(() => {
            ghostInput.current.focus()

            setTimeout(() => {
                ghostInput.current.focus()

                setTimeout(() => {
                    ghostInput.current.focus()


                    // ghostInput.current.click()
                }, 100);

                // ghostInput.current.click()
            }, 100);

            // ghostInput.current.click()
        }, 100);
    }

    const textIds = _.get(models, [selectedModelId, 'textIds'])

    useEffect(() => {
        if (ghostInputValue.endsWith('\n')) {
            const newValue = `${ghostInputValue} `
            setGhostInputValue(newValue)
            userChangeTextById(selectedModelId, selectedTextId, newValue);
            changeBlinkingCursorPos(Def.canvas.getActiveObject())
        }
    }, [ghostInputValue])

    const onGhostInputChange = (newValue: string) => {
        if (ghostInputValue.endsWith('\n ') && newValue.endsWith('\n')) {
            newValue = newValue.substring(0, newValue.length - 1)
        }
        setGhostInputValue(prev => newValue)
        userChangeTextById(selectedModelId, selectedTextId, newValue);

        changeBlinkingCursorPos(Def.canvas.getActiveObject(), ghostInputValue, newValue)
    }

    if (!isCanvasLoaded) {
        return <CircularProgress />;
    }

    if (isMobile) {
        return <>
            <textarea
                id="ghost-input"
                ref={ghostInput}
                // type="text"
                value={ghostInputValue}
                onChange={e => onGhostInputChange(e.target.value)}
                style={{
                    width: '1px',
                    height: '1px',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    color: 'red'
                }}
                onBlur={() => {
                    hideBlinkingCursor()
                }}
            />
        </>
        return <input
            id="ghost-input"
            ref={ghostInput}
            type="text"
            value={ghostInputValue}
            onChange={e => onGhostInputChange(e.target.value)}
        // style={{ position: 'fixed', top: -1270 }}
        />
    }

    console.log('selectedTextId', selectedTextId)

    console.log(`textids: `, textIds)

    return (
        <div
            style={{
                transition: 'all .3s',
                position: 'relative',
                left: textSelected ? 0 : -320,
                backgroundColor: '#fafafa',
                height: '100%'
            }}
        >
            <div style={{ width: 320, backgroundColor: KOOL_GREEN, height: '100%' }}>
                <SideSectionHeader isMobile={isMobile} primaryText="Texte" secondaryText="JE PERSONNALISE" />

                {texts.map((d, index) => (
                    <div key={`${selectedModelId}-${index}`} className="UserCustomizeText-hover" style={{ position: 'relative', padding: '0 20px' }} >
                        <Input
                            title={textTitles[index]}
                            placeholder={textPlaceHolders[index]}
                            value={texts[index]}
                            setValue={text => {
                                userChangeText(selectedModelId, index, text);
                                // setSelectedTextId('')
                                // changeUserText(index, text);
                            }}
                            maxCharacter={textCharacterLimits[index]}
                            disabled={textIds[index] !== selectedTextId}
                        />
                        {texts.length > 1 && (
                            <div className="UserCustomizeText-remove-icon">
                                <IconButton onClick={() => {
                                    userRemoveText(selectedModelId, index)
                                }}>
                                    <RemoveCircleOutlineIcon color="error" />
                                </IconButton>
                            </div>
                        )}
                    </div>
                ))}

                {texts.length < maxTextLinesByModels[selectedModelId] && (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                        <Button onClick={() => userAddText(selectedModelId)} variant="contained" color="primary">Ajouter une ligne de texte</Button>
                    </div>
                )}

                <div style={{ height: 16 }} />

                <TextModifierSettings />
            </div>
        </div>
    );
}

export default UserCustomizeText;
