import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/database"
import '@firebase/storage';

import _ from "lodash"
import { useEffect, useState } from "react"
import { user as user$ } from "rxfire/auth"
import { collectionData, docData } from "rxfire/firestore"
import { object } from 'rxfire/database';
import { tap } from "rxjs/operators"
import { v4 as uuid } from 'uuid';


let config = {
  apiKey: "AIzaSyB6VvlyEw37Rc4dB9JwMBGSeCNv39We03A",
  authDomain: "monzaya-1f761.firebaseapp.com",
  projectId: "monzaya-1f761",
  storageBucket: "monzaya-1f761.appspot.com",
  messagingSenderId: "879257729008",
  appId: "1:879257729008:web:dc771161e567f223aecf35",
  measurementId: "G-GVQTY7X068",
  databaseURL: "https://monzaya-1f761.europe-west1.firebasedatabase.app/"
}

interface IFirebase {
  app?: firebase.app.App;
  auth?: firebase.auth.Auth;
  firebase?: any;
  firestore?: firebase.firestore.Firestore;
  database?: firebase.database.Database;
}

export const useFirebase = () => {
  let [state, setState] = useState<IFirebase>({
    firebase,
  })
  useEffect(() => {
    let app
    if (!firebase.apps.length) {
      app = firebase.initializeApp(config)
      firebase.firestore().enablePersistence()
    }
    let auth = firebase.auth(app)
    let firestore = firebase.firestore(app)
    let database = firebase.database(app)
    setState({ app, auth, firebase, firestore, database })
  }, [])
  return state
}

export const useUser = () => {
  let [state, setState] = useState<{
    loading: boolean,
    user: any
  }>({
    loading: true,
    user: null,
  })
  let { auth } = useFirebase()
  useEffect(() => {
    if (!auth) {
      return
    }
    let subscription = user$(auth).subscribe(async user => {
      user?.getIdTokenResult(true).then()
      setState({ user, loading: false })
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [auth])
  return state
}

export const useFirestoreCollection = <T>(...location) => {
  const path = location.join("/")
  const { firestore: firestoreInstance } = useFirebase()
  const [state, setState] = useState({
    loading: true,
    data: [],
  })

  const [orderBy, setOrderBy] = useState<string>()
  const [limit, setLimit] = useState(30)
  useEffect(() => {
    if (_.size(_.compact(location)) != _.size(location)) {
      return
    }
    setState(state => ({
      ...state,
      loading: true,
    }))

    let query = firebase
      .firestore()
      .collection(`${path}`)

      .limit(limit)

    query = orderBy ? query.orderBy(orderBy, "desc") : query
    let subscription = collectionData(query, "id").subscribe(data => {
      setState({
        loading: false,
        data,
      })
    })
    return () => subscription.unsubscribe()
  }, [path, orderBy, limit, firestoreInstance])
  const createRecordWithId = async (data, id) => {

    return await firebase
      .firestore()
      .collection(`${path}`)
      .doc(id)
      .set({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  }
  const createRecord = async (data, batch = null) => {

    if (batch) {
      let ref = firebase.firestore().collection(`${path}`).doc()
      batch.set(ref, {
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      return batch
    }
    return await firebase
      .firestore()
      .collection(`${path}`)
      .add({
        ...data,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
  }

  const updateRecord = async (id, data) => {
    return firebase
      .firestore()
      .doc(`${path}/${id}`)
      .set(
        {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          ...data,
        },
        {
          merge: true,
        }
      )
  }

  const deleteRecord = id => {
    return firebase.firestore().doc(`${path}/${id}`).delete()
  }

  return {
    createRecord,
    updateRecord,
    deleteRecord,
    createRecordWithId,
    setOrderBy,
    setLimit,
    limit,
    ...state,
  }
}

export const useFirestoreDocument = <T>(...location) => {
  const path = location.join("/")
  const [data, setData] = useState<T>()
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    if (_.size(_.compact(location)) != _.size(location)) {
      return
    }

    if (path.split('/').length % 2) {
      return;
    }

    setLoading(true)
    let query = firebase.firestore().doc(`${path}`)
    let subscription = docData<T>(query, "id")
      .pipe(tap(() => setLoading(false)))
      .subscribe(setData)
    return () => subscription.unsubscribe()
  }, [location])

  const updateRecord = async (data: T) => {
    return firebase
      .firestore()
      .doc(`${path}`)
      .set(
        {
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          ...data,
        },
        {
          merge: true,
        }
      )
  }

  const deleteRecord = () => {
    return firebase.firestore().doc(`${path}`).delete()
  }

  return {
    updateRecord,
    deleteRecord,
    data,
    loading,
  }
}

export const writeDoc = (ref: string, data: any) => {
  return firebase.firestore().doc(ref).set(data, { merge: true });
}

export const writeRTDB = (ref: string, data: any) => {
  return firebase.database().ref(ref).set({ ...data, modifiedAt: firebase.database.ServerValue.TIMESTAMP });
}

export const updateRTDB = (ref: string, data: any) => {
  return firebase.database().ref(ref).update({ ...data, modifiedAt: firebase.database.ServerValue.TIMESTAMP });
}

export const pushRTDB = (ref: string, data: any) => {
  return firebase.database().ref(ref).push({ ...data, modifiedAt: firebase.database.ServerValue.TIMESTAMP });
}

export const addDoc = (ref: string, data) => {
  return firebase.firestore().collection(ref).add(data);
}

export const listenDoc = (ref: string) => {
  return docData(firebase.firestore().doc(ref));
}

export const listenRTDB = (ref: string) => {
  return object(firebase.database().ref(ref));
}

export const getDoc = (ref: string) => {
  return firebase.firestore().doc(ref).get().then(doc => doc.data());
}

export const getRTDB = (ref: string) => {
  return new Promise(res => {
    firebase.database().ref(ref).once('value', snapshot => {
      res(snapshot.val())
    })
  })
}
// firebase.database().ref(ref).get().then(data => data.val());

// export const getRTDB = (ref: string) =>
//   firebase.database().ref(ref).get().then(data => data.val());

export const useFirestoreStorage = <T>(...location) => {
  const path = location.join("/")
  const [loading, setLoading] = useState(false)
  const { firestore: firestoreInstance } = useFirebase()
  const [storage, setStorage] = useState(null)
  useEffect(() => {
    setStorage(firebase.storage().ref(path))
  }, [firestoreInstance])
  const upload = async (file) => {
    setLoading(true);
    const metadata = {
      cacheControl: 'public,max-age=3600',
    }
    const result = await storage.child(file.name).put(file, metadata)
    setLoading(false)
    return result
  }

  const getUrl = async (name) => {
    if (!storage) return
    setLoading(true)
    const result = await storage.child(name).getDownloadURL()
    return result
  }

  return {
    upload,
    getUrl,
    loading
  }
}

export const upload = async (file) => {
  const id = uuid();
  const storageRef = firebase.storage().ref(`images/${id}`)
  const metadata = {
    cacheControl: 'public,max-age=3600',
  }
  await storageRef.put(file, metadata)
  return storageRef.getDownloadURL();
}

export const putString = async (path: string, str: string) => {
  const storageRef = firebase.storage().ref(path + '.jpg');
  const metadata = {
    cacheControl: 'public,max-age=3600',
    contentType: 'image/jpg'
  }
  await storageRef.putString(str, "data_url", metadata);
  return storageRef.getDownloadURL();
}