import { Box } from '@material-ui/core';
import React from 'react'
import './style.scss'

const TextIcon = (props) => {
    const { name, selected, id, onClick:click, src, isMobile = false } = props;

    return !isMobile 
        ? (
            <Box onClick={click} className='text-icon' style={{ cursor: 'pointer' }}>
                {/* <div style={{width: 4, height: 24, backgroundColor: '#354530'}}></div> */}
                <img style={{ width: 24, opacity: selected === id ? 1 : 0.57, marginRight: 16 }} src={src} alt="image" />
                <p style={{color: selected == id ? '#09101D' : '#858C94'}}>{name}</p>
            </Box>
        )
        : (
            <div onClick={click} className='text-icon' style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* <div style={{width: 4, height: 24, backgroundColor: '#354530'}}></div> */}
                <img style={{ height: 24, opacity: selected === id ? 1 : 0.57 }} src={src} alt="image" />
                <p style={{color: selected === id ? '#09101D' : '#858C94', margin: '4px 0'}}>{name}</p>
            </div>
        )
}

export default TextIcon;