import _ from 'lodash';
import React, { useContext, useEffect } from 'react';
import { EditProductContext, IUndoRedoType } from '../../contexts/EditProductContext';
import { WindowResolutionContext } from '../../contexts/WindowResolutionContext';
import MaterialSelection from './MaterialSelection';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import './ModelSelection.scss';

const ModelsSelection = () => {
    const { selectedModelId, isAdmin, models, selectModel, addUndoRedo, product } = useContext(EditProductContext);
    const { isMobile } = useContext(WindowResolutionContext);

    const onModelSelect = (modelId: string) => {
        addUndoRedo({
            type: IUndoRedoType.ModelChange,
            modelId: selectedModelId
        }, {
            type: IUndoRedoType.ModelChange,
            modelId: modelId
        })
        selectModel(modelId, isAdmin);
    }

    const getModelPreviewImageUrl = (model) => {
        return _.get(model, 'previewImageUrl') 
            ? _.get(model, 'previewImageUrl')
            : _.get(model, 'modelImageUrl')
    }

    const getSortedModels = (models: any[]) => {
        const sortStage1 = _.sortBy(_.values(models), 'modelName');
        for (let i = 0; i < sortStage1.length; i++) {
            for (let j = i + 1; j < sortStage1.length; j++) {
                if (Number(sortStage1[i].modelName.replace(/\D/g, '')) > Number(sortStage1[j].modelName.replace(/\D/g, ''))) {
                    const tmp = JSON.parse(JSON.stringify(sortStage1[i]))
                    sortStage1[i] = JSON.parse(JSON.stringify(sortStage1[j]))
                    sortStage1[j] = JSON.parse(JSON.stringify(tmp))
                }
            }
        }
        return sortStage1
    }

    return (
        <div>
            <MaterialSelection />
            
        </div>
    )
}

export default ModelsSelection;
