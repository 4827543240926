import React, { useState, createContext, useCallback, useRef } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useApi } from '../hooks/useApi';
import { v4 as uuid } from 'uuid';
import { AddBoundary, AddUserImageBoundary, AddModelImage, ChangeBackgroundImage, ChangeImageUrlById, ChangeModelColor, Def, Deselect, LoadFromJson, LockLockedItems, MakeBgVisible, MakeBoundaryVisible, MakeImageBoundaryVisible, MakeModelItemsVisible, MakePageItemsVisible, RemoveBoundary, RemoveUserImageBoundary, RemoveModel, RemoveModelImage, RemoveObjectById, RenderAll, ResetModelColor, ToJson, UnlockAllItems, MakeUserImageVisible, ChangeTextColor, GetObjectById } from '../components/Customize/Customize';
import { changeText as changeTextFromCustomize } from './../components/Customize/group';
import { getDoc, getRTDB, upload, useFirestoreDocument, writeDoc, writeRTDB } from '../firebase/firebase';
import _ from 'lodash';
import { fabric } from "fabric"
import { DEFAULT_MODEL_COLOR } from '../components/Models/EditModel/EditModelColor';
interface IBoundary {
    boundaryId: string;
    itemId: string;
}

interface IUndoRedoTransform {
    scaleX: number;
    scaleY: number;
    angle: number;
    left: number;
    top: number;
}

export enum IUndoRedoType {
    Transform,
    MaterialChange,
    ModelChange,
}

interface IUndoRedoAction {
    type: IUndoRedoType;
    transform?: IUndoRedoTransform;
    materialIndex?: number;
    modelId?: string;
    object?: any;
}

export const EditProductContext = createContext<{
    productId?: string;
    setProductId?: Function;
    backgroundImageFiles?: File[];
    setBackgroundImageFile?: Function;
    backgroundImageLocalSrcs?: string[];
    selectedBgIndex?: number;
    setSelectedBgIndex?: Function;
    product?: any;
    modelImageSrcs?: string[];
    addModelImages?: Function;
    removeModelImage?: Function;
    modelImageIds?: string[];
    addText?: Function;
    texts?: string[];
    textIds?: string[];
    changeText?: Function;
    textPlaceholders?: string[];
    textTitles?: string[];
    changeTextPlaceholder?: Function;
    changeTextTitles?: Function;
    changeBackgoundImage?: Function;
    save?: Function;
    saving?: boolean;
    bgUploading?: boolean[];
    modelUploading?: boolean[];
    removeText?: Function;
    firestoreData?: any;

    models?: any;
    addModel?: Function;
    selectModel?: Function;
    selectedModelId?: string;
    updateModelData?: Function;
    lockedItemIds?: string[];
    toggleLockedItem?: Function;
    isLocked?: Function;
    backgroundImageSrcs?: string[];
    isAdmin?: boolean;
    setIsAdmin?: Function;
    userChangeText?: Function;
    userChangeTextById?: Function;
    toggleBoundaryItem: Function;
    boundaries: any;
    hasBoundary: Function;
    updateItemBoundary: Function;
    changeTextById: Function;

    pageIds: string[];
    pageNames: string[];
    selectedPageId: string;
    selectedPageIndex: number;
    changeMaterial: Function;
    changePageName: Function;
    addPage: Function;
    removePage: Function;
    pageImageUrls: string[];
    changePageImage: Function;
    uploadingPageImage: boolean[];
    nextPage: Function;
    prevPage: Function;
    changePage: Function;
    removeModel: Function;

    personalisationPrices: any[];
    selectedPersonalisationPriceId: string;
    setSelectedPersonalisationPriceId: Function;

    // hasMonoColor: boolean;
    // setHasMonoColor: Function;
    // color: any;
    // changeColor: Function;
    // resetColor: Function;
    colors: string[];
    hasMonoColor: boolean;
    setHasMonoColor: Function;
    changeColor: Function;
    resetColor: Function;

    undoRedoHistory: IUndoRedoAction[];
    undoRedoIndex: number;
    addUndoRedo: (actionBefore: IUndoRedoAction, current) => void;
    undo: Function;
    redo: Function;
    canRedo: boolean;
    canUndo: boolean;

    // hasImages: boolean;
    askHasImage: Function;
    turnOnImage: Function;
    turnOffImage: Function;
    updateUserImageBoundary: Function;
    getImageBoundaryId: Function;

    applyColor: Function;
    readyToDownload: boolean;
    setReadyToDownload: Function;

    userRemoveText: Function;
    userRemoveTextLS?: Function;
    isCanvasLoaded: boolean;
    userAddText: Function;

    loading?: boolean
}>(null)

const FIRST_PAGE_ID = 'page-1';

const EditProductContextProvider = ({ children }) => {
    const [productId, setProductId] = useState(null);
    const [backgroundImageSrcsPage, setBackgroundImageSrcsPage] = useState<{ [pageId: string]: string[] }>({ [FIRST_PAGE_ID]: _.range(50).map(d => "") });
    const [bgUploading, setBgUploading] = useState<boolean[]>(_.range(50).map(d => false));
    const { data: productData, loading: productDataLoading } = useApi('/getProduct?id=' + productId);
    const { product } = productData || { product: {} };
    const [selectedBgIndex, setSelectedBgIndex] = useState(-1);
    const [modelImageSrcsPage, setModelImageSrcsPage] = useState<{ [pageId: string]: string[] }>({ [FIRST_PAGE_ID]: [] });
    const [modelUploadingPage, setModelUploadingPage] = useState<{ [pageId: string]: boolean[] }>({ [FIRST_PAGE_ID]: [] });
    const [modelImageIdsPage, setModelImageIdsPage] = useState<{ [pageId: string]: string[] }>({ [FIRST_PAGE_ID]: [] });
    const [textsPage, setTextsPage] = useState<{ [pageId: string]: string[] }>({ [FIRST_PAGE_ID]: [] });
    const [textIdsPage, setTextIdsPage] = useState<{ [pageId: string]: string[] }>({ [FIRST_PAGE_ID]: [] });
    const [textPlaceHoldersPage, setTextPlaceHoldersPage] = useState<{ [pageId: string]: string[] }>({ [FIRST_PAGE_ID]: [] });
    const [textTitlesPage, setTextTitlesPage] = useState<{ [pageId: string]: string[] }>({ [FIRST_PAGE_ID]: [] });
    const [saving, setSaving] = useState(false);
    const [firestoreData, setFirestoreData] = useState<any>(null);
    const [fsDataLoading, setFsDataLoading] = useState(true)

    const [modelsPage, setModelsPage] = useState<{ [pageId: string]: any }>({ [FIRST_PAGE_ID]: {} });
    const [selectedModelIdPage, setSelectedModelIdPage] = useState<{ [pageId: string]: string }>({ [FIRST_PAGE_ID]: null });
    const [lockedItemIds, setLockedItemIds] = useState<string[]>([]);
    const [boundaries, setBoundaries] = useState<any>({});
    const [isAdmin, setIsAdmin] = useState(false);

    const [pageIds, setPageIds] = useState<string[]>([FIRST_PAGE_ID]);
    const [pageNames, setPageNames] = useState<string[]>(['Page 1']);
    const [selectedPageIndex, setSelectedPageIndex] = useState(0);
    const [pageImageUrls, setPageImageUrls] = useState<string[]>(['https://cdn.shopify.com/s/files/1/0578/5944/0813/files/monzaya_logo.svg?v=1627128595']);
    const [uploadingPageImage, setUploadingPageImage] = useState([false]);

    const selectedPageId = pageIds[selectedPageIndex];
    const modelUploading = modelUploadingPage[selectedPageId];
    const models = modelsPage[selectedPageId];
    const selectedModelId = selectedModelIdPage[selectedPageId];
    const texts = textTitlesPage[selectedPageId];
    const textIds = textIdsPage[selectedPageId];
    const textPlaceHolders = textPlaceHoldersPage[selectedPageId];
    const textTitles = textTitlesPage[selectedPageId];
    const backgroundImageSrcs = backgroundImageSrcsPage[selectedPageId];

    const [personalisationPrices, setPersonalisationPrices] = useState<any[]>([]);
    const [selectedPersonalisationPriceId, setSelectedPersonalisationPriceId] = useState<string>(null);

    const [colors, setColors] = useState<string[]>([]);
    const [hasMonoColor, setHasMonoColor] = useState(false);

    const [undoRedoHistory, setUndoRedoHistory] = useState<IUndoRedoAction[]>([]);
    const [undoRedoLastAction, setUndoRedoLastAction] = useState<IUndoRedoAction>(null);
    const [undoRedoIndex, setUndoRedoIndex] = useState(-1);

    const [hasImages, setHasImages] = useState<{ [modelId: string]: boolean }>({});

    const [readyToDownload, setReadyToDownload] = useState(false);

    const [isCanvasLoaded, setIsCanvasLoaded] = useState(false);
    const loading = fsDataLoading || productDataLoading

    useEffect(() => {
        if (productId == null) {
            return;
        }
        reset();
        // getDoc(`products/${productId}`).then(data => {
        //     setFirestoreData(data);
        // }).catch(e => {
        // });

        setFsDataLoading(true)

        getRTDB(`products/${productId}`).then(data => {
            setFirestoreData(data);
            setFsDataLoading(false)

            // console.log(`read products/${productId}`)

            // if (_.get(data, 'canvasJson') == null) {
            //     setIsCanvasLoaded(true);
            // }

        }).catch(e => {
            setFsDataLoading(false)
        });

    }, [productId]);

    useEffect(() => {
        load();
    }, [firestoreData]);

    useEffect(() => {
        const color = colors[selectedBgIndex] ? colors[selectedBgIndex] : DEFAULT_MODEL_COLOR;
        _.keys(models)
            .forEach(modelId => ChangeModelColor(modelId, color));
    }, [selectedBgIndex])

    const reset = () => {
        setIsCanvasLoaded(false);
        setBackgroundImageSrcsPage({ [FIRST_PAGE_ID]: _.range(50).map(d => "") });
        setBgUploading(_.range(50).map(d => false));
        setSelectedBgIndex(-1);
        setModelImageSrcsPage({ [FIRST_PAGE_ID]: [] });
        setModelUploadingPage({ [FIRST_PAGE_ID]: [] });
        setModelImageIdsPage({ [FIRST_PAGE_ID]: [] });
        setTextsPage({ [FIRST_PAGE_ID]: [] });
        setTextIdsPage({ [FIRST_PAGE_ID]: [] });
        setTextPlaceHoldersPage({ [FIRST_PAGE_ID]: [] });
        setTextTitlesPage({ [FIRST_PAGE_ID]: [] });
        setSaving(false);
        setModelsPage({ [FIRST_PAGE_ID]: {} });
        setSelectedModelIdPage({ [FIRST_PAGE_ID]: null });
        setLockedItemIds([]);
        setBoundaries({});
        setSelectedPersonalisationPriceId(null);
        setPageIds([FIRST_PAGE_ID]);
        setPageNames(['Page 1']);
        setSelectedPageIndex(0);
        setPageImageUrls(['https://cdn.shopify.com/s/files/1/0578/5944/0813/files/monzaya_logo.svg?v=1627128595']);
        setUploadingPageImage([false]);
        setColors([]);
        setHasMonoColor(false);
    }

    const addUndoRedo = (undoRedoAction: IUndoRedoAction, current: IUndoRedoAction) => {
        setUndoRedoHistory([...undoRedoHistory, undoRedoAction, current]);
        setUndoRedoIndex(undoRedoHistory.length - 1 + 2);
    }

    const undo = () => {
        const newUndoRedoIndex = undoRedoIndex % 2 === 0
            ? undoRedoIndex - 2
            : undoRedoIndex - 1;

        if (newUndoRedoIndex < 0) {
            return;
        }

        setUndoRedoIndex(newUndoRedoIndex);
        updateUndoRedoAction(undoRedoHistory[newUndoRedoIndex]);
    }

    const canUndo = (undoRedoIndex % 2 === 0
        ? undoRedoIndex - 2
        : undoRedoIndex - 1) >= 0;

    const redo = () => {
        const newUndoRedoIndex = undoRedoIndex % 2 == 0
            ? undoRedoIndex + 1
            : undoRedoIndex + 2;

        if (newUndoRedoIndex >= undoRedoHistory.length) {
            return;
        }

        setUndoRedoIndex(newUndoRedoIndex);
        updateUndoRedoAction(undoRedoHistory[newUndoRedoIndex]);
    }

    const canRedo = (undoRedoIndex % 2 == 0
        ? undoRedoIndex + 1
        : undoRedoIndex + 2) < undoRedoHistory.length;

    const updateUndoRedoAction = (action: IUndoRedoAction) => {
        if (!action) {
            return;
        }
        switch (action.type) {
            case IUndoRedoType.Transform:
                const object = action.object;
                if (object) {
                    object.left = action.transform.left;
                    object.top = action.transform.top;
                    object.angle = action.transform.angle;
                    object.scaleX = action.transform.scaleX;
                    object.scaleY = action.transform.scaleY;
                    RenderAll();
                }
                break;
            case IUndoRedoType.MaterialChange:
                changeMaterial(action.materialIndex);
                break;
            case IUndoRedoType.ModelChange:
                selectModel(action.modelId, isAdmin);
                break;
        }
    }

    const changeMaterial = (index) => {
        setSelectedBgIndex(index);
        MakeBgVisible(selectedPageId, index, isAdmin);
    }

    const changeColor = (materialIndex: number, color: any) => {
        if (selectedBgIndex === materialIndex) {
            _.keys(models)
                .forEach(modelId => ChangeModelColor(modelId, color));
        }

        colors[materialIndex] = color;
        setColors([...colors]);
        setHasMonoColor(true);
    }

    const resetColor = () => {
        // if (selectedBgIndex === materialIndex) {
        _.keys(models)
            .forEach(modelId => ResetModelColor(modelId));
        // }

        setColors([]);
        setHasMonoColor(false);
    }

    const applyColor = () => { }

    const changePageName = (index: number, text: string) => {
        pageNames[index] = text;
        setPageNames([...pageNames]);
    }

    const addPage = () => {
        const id = `page-${uuid()}`;
        setPageNames([...pageNames, 'Шинэ']);
        setPageImageUrls([...pageImageUrls, 'https://cdn.shopify.com/s/files/1/0578/5944/0813/files/monzaya_logo.svg?v=1627128595']);
        setPageIds([...pageIds, id]);

        setModelImageIdsPage({
            ...modelImageIdsPage,
            [id]: []
        });
        setModelImageSrcsPage({
            ...modelImageSrcsPage,
            [id]: []
        });
        setModelUploadingPage({
            ...modelUploadingPage,
            [id]: []
        });
        setBgUploading(_.range(50).map(d => false));
        setBackgroundImageSrcsPage({
            ...backgroundImageSrcsPage,
            [id]: _.range(50).map(d => "")
        })
    }

    const removePage = (index: number) => {
        if (pageIds.length < 2) {
            return;
        }
        if (uploadingPageImage.includes(true)) {
            return;
        }
        pageNames.splice(index, 1);
        pageIds.splice(index, 1);
        setPageNames([...pageNames]);
        setPageIds([...pageIds]);
    }

    const changePageImage = async (index: number, file: File) => {
        if (file == null) {
            return;
        }
        const src = await getLocalSrc(file);
        uploadingPageImage[index] = true;
        setUploadingPageImage([...uploadingPageImage]);

        upload(file).then(url => {
            uploadingPageImage[index] = false;
            pageImageUrls[index] = url;
            setUploadingPageImage([...uploadingPageImage]);
            setPageImageUrls([...pageImageUrls]);
        });
    }

    const nextPage = () => {
        if (selectedPageIndex === pageNames.length - 1) {
            setSelectedPageIndex(0)
            return;
        }
        changePage(selectedPageIndex + 1);
    }

    const prevPage = () => {
        if (selectedPageIndex === 0) {
            return;
        }
        changePage(selectedPageIndex - 1);
    }

    const changePage = (pageIndex: number) => {
        MakePageItemsVisible(pageIds[pageIndex], isAdmin);
        MakeModelItemsVisible(selectedModelIdPage[pageIds[pageIndex]], isAdmin);
        MakeBgVisible(pageIds[pageIndex], selectedBgIndex, isAdmin);
        MakeBoundaryVisible('');
        // MakeImageBoundaryVisible(hasImages);
        // MakeImageBoundaryVisible(getImageBoundaryId(), askHasImage());

        MakeUserImageVisible();
        setSelectedPageIndex(pageIndex);
    }

    const toggleBoundaryItem = (itemId: string) => {
        if (hasBoundary(itemId)) {
            const boundaryId = boundaries[itemId];
            delete boundaries[itemId];;
            setBoundaries({ ...boundaries });
            RemoveBoundary(boundaryId, itemId);
        } else {
            const boundaryId = `boundary-${itemId}`;
            AddBoundary(boundaryId, itemId);
            setBoundaries({ ...boundaries, [itemId]: boundaryId });
        }
    }

    const hasBoundary = (itemId: string) => boundaries[itemId] != null;

    const toggleLockedItem = (itemId: string) => {
        let newLockedItems;
        if (isLocked(itemId)) {
            newLockedItems = lockedItemIds.filter(d => d !== itemId)
        } else {
            newLockedItems = [...lockedItemIds, itemId];
        }
        setLockedItemIds([...newLockedItems]);

        LockLockedItems(newLockedItems);

        MakePageItemsVisible(FIRST_PAGE_ID, isAdmin);
        MakeModelItemsVisible(selectedModelId, isAdmin);
        MakeBgVisible(FIRST_PAGE_ID, selectedBgIndex, isAdmin);
        MakeBoundaryVisible('');
        // MakeImageBoundaryVisible(hasImages)
        MakeImageBoundaryVisible(getImageBoundaryId(), askHasImage());
    }

    const isLocked = (itemId: string) => lockedItemIds.includes(itemId);

    // const decideLockItems = () => {
    //     if (isAdmin) {
    //         UnlockAllItems();
    //     } else {
    //         LockLockedItems(firestoreData['lockedItemIds'])
    //     }
    // }

    const load = () => {
        if (_.get(firestoreData, 'canvasJson') != null) {
            LoadFromJson(firestoreData['canvasJson'], async () => {
                await LockLockedItems(firestoreData['lockedItemIds'])
                const selectedModelId = _.get(firestoreData, ['selectedModelIdPage', FIRST_PAGE_ID], '0');

                MakePageItemsVisible(FIRST_PAGE_ID, isAdmin);
                MakeModelItemsVisible(selectedModelId, isAdmin);
                MakeBgVisible(FIRST_PAGE_ID, _.get(firestoreData, ['selectedBgIndex'], 0), isAdmin);
                MakeBoundaryVisible('');
                const selectedModelHasImage = _.get(firestoreData, ['hasImages', selectedModelId], false)
                MakeImageBoundaryVisible(getImageBoundaryId(selectedModelId), selectedModelHasImage);

                setIsCanvasLoaded(true);

                selectModel('0', isAdmin)
                selectModel(_.get(firestoreData, 'selectedModelIdPage.page-1'), isAdmin)
            });
        } else {
            setIsCanvasLoaded(true);
        }

        if (_.get(firestoreData, 'selectedBgIndex')) {
            setSelectedBgIndex(_.get(firestoreData, 'selectedBgIndex'));
        }

        if (_.get(firestoreData, 'backgroundImageSrcsPage') != null) {
            let imagesPage = _.get(firestoreData, 'backgroundImageSrcsPage')
            const keys = Object.keys(imagesPage)
            for (const key of keys) {
                for (let i = 0; i < 50; i++) {
                    if (imagesPage[key][i] == null) {
                        imagesPage[key][i] = ""
                    }
                }
            }
            setBackgroundImageSrcsPage(imagesPage)
        }
        if (_.get(firestoreData, 'modelsPage') != null) {
            setModelsPage(_.get(firestoreData, 'modelsPage'));
        }

        if (_.get(firestoreData, 'lockedItemIds') != null) {
            setLockedItemIds(firestoreData['lockedItemIds']);
        }

        if (_.get(firestoreData, 'boundaries') != null) {
            setBoundaries(firestoreData['boundaries']);
        }

        if (_.get(firestoreData, 'selectedModelIdPage') != null) {
            setSelectedModelIdPage(_.get(firestoreData, 'selectedModelIdPage'));
        }

        if (_.get(firestoreData, 'selectedPersonalisationPriceId') != null) {
            setSelectedPersonalisationPriceId(_.get(firestoreData, 'selectedPersonalisationPriceId'));
        }

        if (_.get(firestoreData, 'selectedBgIndex') != null) {
            setSelectedBgIndex(_.get(firestoreData, 'selectedBgIndex'));
        }

        if (_.get(firestoreData, 'pageIds') != null) {
            setPageIds(_.get(firestoreData, 'pageIds'));
        }

        if (_.get(firestoreData, 'pageNames') != null) {
            setPageNames(_.get(firestoreData, 'pageNames'));
        }

        if (_.get(firestoreData, 'pageImageUrls') != null) {
            setPageImageUrls(_.get(firestoreData, 'pageImageUrls'));
        }

        if (_.get(firestoreData, 'hasMonoColor') != null) {
            setHasMonoColor(_.get(firestoreData, 'hasMonoColor'));
        }

        if (_.get(firestoreData, 'hasImages') != null) {
            setHasImages(_.get(firestoreData, 'hasImages', {}));
        }

        if (_.get(firestoreData, 'colors') != null) {
            setColors(_.get(firestoreData, 'colors'));
        }
    }

    // const [uMId, setUMId] = useState<string>();
    // const [uIndex, setUIndex] = useState<number>();
    // const [uText, setUText] = useState<string>();
    // useEffect(() => {
    //     if (uMId) {
    //         changeTextById(models[uMId]['textIds'][uIndex], uText);
    //     }
    // }, [uText]);

    const userChangeText = (modelId: string, index: number, text: string) => {
        if (text.length === 0) {
            text = ' ';
        }
        if (text.length === 2 && text[0] === ' ') {
            text = text.slice(1, text.length);
        }

        modelsPage[selectedPageId][modelId]['texts'][index] = text;
        setModelsPage({ ...modelsPage });

        // setUMId(modelId);
        // setUIndex(index);
        // setUText(text);

        changeTextById(models[modelId]['textIds'][index], text)
    }

    const userChangeTextById = (modelId: string, id: string, text: string) => {
        if (text.length === 0) {
            text = ' ';
        }
        if (text.length === 2 && text[0] === ' ') {
            text = text.slice(1, text.length);
        }

        const index = modelsPage[selectedPageId][modelId]['textIds'].findIndex(s => s === id)

        if (index == -1) {
            return
        }

        modelsPage[selectedPageId][modelId]['texts'][index] = text;
        setModelsPage({ ...modelsPage });

        // setUMId(modelId);
        // setUIndex(index);
        // setUText(text);

        changeTextById(models[modelId]['textIds'][index], text)
    }
    const [removedTextStylesCache, setRemovedTextStylesCache] = useState<any>({});

    useEffect(() => {
        localStorage.setItem('selectedPageId', `${pageIds[selectedPageIndex]}`)
        localStorage.setItem('modelsPage', JSON.stringify(modelsPage))
        localStorage.setItem('models', JSON.stringify(models))
        localStorage.setItem('removedTextStylesCache', JSON.stringify(removedTextStylesCache))
    }, [JSON.stringify({ pageIds, selectedPageIndex, modelsPage, models, removedTextStylesCache })])


    const userRemoveText = (modelId: string, index: number) => {
        // const selectedPageId = localStorage.getItem('selectedPageId')
        // const modelsPage = JSON.parse(localStorage.getItem('modelsPage'))
        // const models = JSON.parse(localStorage.getItem('models'))
        // const removedTextStylesCache = JSON.parse(localStorage.getItem('removedTextStylesCache'))

        if (!modelsPage[selectedPageId][modelId]) {
            return
        }
        modelsPage[selectedPageId][modelId]['textPlaceHolders'].splice(index, 1);
        modelsPage[selectedPageId][modelId]['textTitles'].splice(index, 1);
        modelsPage[selectedPageId][modelId]['texts'].splice(index, 1);
        const [textId] = models[modelId]['textIds'].splice(index, 1);

        setModelsPage({ ...modelsPage });
        const obj = GetObjectById(textId);

        if (obj) {
            setRemovedTextStylesCache({
                ...removedTextStylesCache,
                [selectedPageId]: {
                    [modelId]: {
                        fontFamily: obj.fontFamily,
                        fontFileName: obj.fontFileName,
                        fontStyle: obj.fontStyle,
                        fontWeight: obj.fontWeight,
                        fill: obj.fill,
                        fontSize: obj.fontSize,
                        scaleX: obj.scaleX,
                        scaleY: obj.scaleY
                    }
                }
            })
        }

        RemoveModel(textId);
    }

    const userRemoveTextLS = (modelId: string, index: number) => {
        setTimeout(() => {
            const selectedPageId = localStorage.getItem('selectedPageId')
            const modelsPage = JSON.parse(localStorage.getItem('modelsPage'))
            const models = JSON.parse(localStorage.getItem('models'))
            const removedTextStylesCache = JSON.parse(localStorage.getItem('removedTextStylesCache'))

            if (!modelsPage[selectedPageId][modelId]) {
                return
            }
            modelsPage[selectedPageId][modelId]['textPlaceHolders'].splice(index, 1);
            modelsPage[selectedPageId][modelId]['textTitles'].splice(index, 1);
            const text = modelsPage[selectedPageId][modelId]['texts'].splice(index, 1);
            models[modelId]['textIds'].splice(index, 1);
            const [textId] = modelsPage[selectedPageId][modelId]['textIds'].splice(index, 1)

            setModelsPage(JSON.parse(JSON.stringify({ ...modelsPage })));
            console.log(selectedPageId, modelId, index, text, textId)
            console.log(models[modelId]['textIds'])
            const obj = GetObjectById(textId);

            if (obj) {
                setRemovedTextStylesCache({
                    ...removedTextStylesCache,
                    [selectedPageId]: {
                        [modelId]: {
                            fontFamily: obj.fontFamily,
                            fontFileName: obj.fontFileName,
                            fontStyle: obj.fontStyle,
                            fontWeight: obj.fontWeight,
                            fill: obj.fill,
                            fontSize: obj.fontSize,
                            scaleX: obj.scaleX,
                            scaleY: obj.scaleY
                        }
                    }
                })
            }

            RemoveModel(textId);
        }, 100)
    }

    const userAddText = (modelId: string) => {
        const id = `model-${selectedPageId}-${modelId}-text-${uuid()}`;
        const defaultText = 'Modifier le texte';
        const color = colors[selectedBgIndex] ? colors[selectedBgIndex] : DEFAULT_MODEL_COLOR;

        modelsPage[selectedPageId][modelId]['textPlaceHolders'].push('Écrivez ici...');
        modelsPage[selectedPageId][modelId]['textTitles'].push('Ligne de texte supplémentaire');
        modelsPage[selectedPageId][modelId]['texts'].push(defaultText);
        models[modelId]['textIds'].push(id);

        setTextsPage({
            ...textsPage,
            [selectedPageId]: [...texts, defaultText]
        });
        setTextIdsPage({
            ...textIdsPage,
            [selectedPageId]: [...textIds, id]
        });
        setTextPlaceHoldersPage({
            ...textPlaceHoldersPage,
            [selectedPageId]: [...textPlaceHolders, 'Écrivez ici...']
        });
        setTextTitlesPage({
            ...textTitlesPage,
            [selectedPageId]: [...textTitles, 'Ligne de texte supplémentaire']
        });

        const cachedTextStyle = _.get(removedTextStylesCache, [selectedPageId, modelId], {});

        Def.addText(defaultText, cachedTextStyle, id, hasMonoColor ? color : 'black');
        Def.changeStyleById(id, 4, cachedTextStyle);

        const obj = GetObjectById(id);
        obj.set('scaleX', cachedTextStyle.scaleX || 1);
        obj.set('scaleY', cachedTextStyle.scaleY || 1);
        obj.set('fill', cachedTextStyle.fill);

        RenderAll();

        setModelsPage({ ...modelsPage });
    }

    const addModel = (pageId: string = 'page-1') => {
        const modelId = `${pageId}-${uuid()}`;
        setModelsPage({
            ...modelsPage,
            [pageId]: { ...models, [modelId]: { modelId } }
        });
    }

    const removeModel = (modelId: string) => {
        delete modelsPage[selectedPageId][modelId];
        setModelsPage({ ...modelsPage });
        RemoveModel(modelId);
    }

    const updateModelData = (id: string, data: any) => {
        setModelsPage({
            ...modelsPage,
            [selectedPageId]: { ...models, [id]: data }
        });
    }

    const selectModel = (id: string, isAdmin: boolean) => {
        setSelectedModelIdPage({
            ...selectedModelIdPage,
            [selectedPageId]: id
        });
        localStorage.setItem('selectedModelId', id)
        MakeModelItemsVisible(id, isAdmin);
        MakeBoundaryVisible('');
        MakeImageBoundaryVisible(getImageBoundaryId(id), askHasImage(id));
        Deselect();
    }

    const turnOnImage = () => {
        setHasImages({ ...hasImages, [selectedModelId]: true });
        AddUserImageBoundary(getImageBoundaryId(selectedModelId));
    }

    const turnOffImage = () => {
        setHasImages({ ...hasImages, [selectedModelId]: false });
        RemoveUserImageBoundary(getImageBoundaryId(selectedModelId));
    }

    const getImageBoundaryId = (modelId: string = selectedModelId) => {
        return `zurag-zuraas-model-${modelId}`;
    }

    const askHasImage = (id: string = selectedModelId) => _.get(hasImages, id, false);

    const getLocalSrc = (file: File) => {
        return new Promise<string>(res => {
            var reader = new FileReader();

            reader.onload = function (event: any) {
                const src = event.target.result;
                res(src);
            };

            reader.readAsDataURL(file);
        });
    }

    const changeBackgoundImage = async (index: number, file: File) => {
        if (file == null) {
            return;
        }
        const src = await getLocalSrc(file);
        bgUploading[index] = true;
        setBgUploading([...bgUploading]);

        upload(file).then(url => {
            ChangeBackgroundImage(selectedPageId, index, url, selectedBgIndex === index);
            bgUploading[index] = false;
            setBgUploading([...bgUploading]);
            setBackgroundImageSrcsPage({
                ...backgroundImageSrcsPage,
                [selectedPageId]: backgroundImageSrcsPage[selectedPageId].map((d, i) => index === i ? url : d)
            });
        });

        setBackgroundImageSrcsPage({
            ...backgroundImageSrcsPage,
            [selectedPageId]: backgroundImageSrcsPage[selectedPageId].map((d, i) => index === i ? src : d)
        });
    }

    const addText = (color = 'black') => {
        const id = `${selectedPageId}-text-${uuid()}`;
        const defaultText = 'Modifier le texte';
        Def.addText(defaultText, {}, id, color);
        setTextsPage({
            ...textsPage,
            [selectedPageId]: [...texts, defaultText]
        });
        setTextIdsPage({
            ...textIdsPage,
            [selectedPageId]: [...textIds, id]
        });
        setTextPlaceHoldersPage({
            ...textPlaceHoldersPage,
            [selectedPageId]: [...textPlaceHolders, 'Placeholder']
        });
        setTextTitlesPage({
            ...textTitlesPage,
            [selectedPageId]: [...textTitles, 'Title']
        });
    }

    const removeText = (index: number) => {
        texts.splice(index, 1);
        const [id] = textIds.splice(index, 1);
        textPlaceHolders.splice(index, 1);
        textTitles.splice(index, 1);

        RemoveObjectById(id);
        setTextsPage({
            ...textsPage,
            [selectedPageId]: [...texts]
        });
        setTextIdsPage({
            ...textIdsPage,
            [selectedPageId]: [...textIds]
        });
        setTextPlaceHoldersPage({
            ...textPlaceHoldersPage,
            [selectedPageId]: [...textPlaceHolders]
        });
        setTextTitlesPage({
            ...textTitlesPage,
            [selectedPageId]: [...textTitles]
        });
    }

    const changeText = (index: number, text: string) => {
        const id = textIds[index];
        setTextsPage({
            ...textsPage,
            [selectedPageId]: [...texts.map((d, i) => i === index ? text : d)]
        });
        changeTextById(id, text);
        ChangeTextColor(id, text);
    }

    const changeTextPlaceholder = (index: number, text: string) => {
        textPlaceHolders[index] = text;
        setTextPlaceHoldersPage({
            ...textPlaceHoldersPage,
            [selectedPageId]: [...textPlaceHolders]
        });
    }

    const changeTextTitles = (index: number, text: string) => {
        textTitles[index] = text;
        setTextTitlesPage({
            ...textTitlesPage,
            [selectedPageId]: [...textTitles]
        });
    }

    // const debouncedChangeHandler = useCallback(
    //     _.throttle(() => changeTextByIdExecution(), 300)
    // , []);

    // const throttled = useRef(_.debounce((id, text) => changeTextByIdExecution(id, text), 10))

    const changeTextByIdExecution = (id, text) => {
        let obj = Def.canvas.getObjects().find(obj => obj.id === id)
        if (obj) {
            changeTextFromCustomize(obj, text)
            obj.selectable = true;
            obj.evented = true;
            Def.canvas.setActiveObject(obj);
            updateItemBoundary(obj);
            obj.opacity = 1
            obj.visible = true
            obj.left++
            Def.canvas.renderAll();
            obj.left--
            Def.canvas.renderAll();
        }
    }


    const changeTextById = (id: string, text: string) => {
        // throttled.current(id, text);
        changeTextByIdExecution(id, text);
    }




    const updateItemBoundary = (target) => {
        const boundaryId = boundaries[target.id];
        if (boundaryId == null) {
            return;
        }
        const boundaryBox = Def.canvas.getObjects().find(obj => obj.id === boundaryId);

        const b_left = boundaryBox.left;
        const b_top = boundaryBox.top;
        const b_right = boundaryBox.left + boundaryBox.width * boundaryBox.scaleX;
        const b_bottom = boundaryBox.top + boundaryBox.height * boundaryBox.scaleY;

        target.setCoords();
        const tmp = target.getBoundingRect();
        const zoom = Def.canvas.getZoom();
        const viewportMatrix = Def.canvas.viewportTransform;

        tmp.top = (tmp.top - viewportMatrix[5]) / zoom;
        tmp.left = (tmp.left - viewportMatrix[4]) / zoom;
        tmp.width /= zoom;
        tmp.height /= zoom;
        const left = tmp.left - 100;
        const top = tmp.top - 100;
        const right = left + tmp.width + 500;
        const bottom = top + tmp.height + 500;


        const o_right = Math.min(right, b_right);
        const o_top = Math.max(top, b_top);
        const o_left = Math.max(left, b_left);
        const o_bottom = Math.min(bottom, b_bottom);

        target.clipPath = new fabric.Rect({
            width: o_right - o_left,
            height: o_bottom - o_top,
            left: o_left,
            top: o_top,
            absolutePositioned: true
        })

        Def.canvas.renderAll();
    }

    const updateUserImageBoundary = (target, boundaryBox) => {
        const b_left = boundaryBox.left;
        const b_top = boundaryBox.top;
        const b_right = boundaryBox.left + boundaryBox.width * boundaryBox.scaleX;
        const b_bottom = boundaryBox.top + boundaryBox.height * boundaryBox.scaleY;

        target.setCoords();
        const tmp = target.getBoundingRect();
        const zoom = Def.canvas.getZoom();
        const viewportMatrix = Def.canvas.viewportTransform;

        tmp.top = (tmp.top - viewportMatrix[5]) / zoom;
        tmp.left = (tmp.left - viewportMatrix[4]) / zoom;
        tmp.width /= zoom;
        tmp.height /= zoom;
        const left = tmp.left - 100;
        const top = tmp.top - 100;
        const right = left + tmp.width + 100;
        const bottom = top + tmp.height + 100;

        const o_right = Math.min(right, b_right);
        const o_top = Math.max(top, b_top);
        const o_left = Math.max(left, b_left);
        const o_bottom = Math.min(bottom, b_bottom);

        target.clipPath = new fabric.Rect({
            width: o_right - o_left,
            height: o_bottom - o_top,
            left: o_left,
            top: o_top,
            absolutePositioned: true
        })

        Def.canvas.renderAll();
    }

    const save = async () => {
        if (saving) {
            return;
        }

        setSaving(true);

        Deselect();

        let hasMonoColor = {};
        _.values(modelsPage).forEach(page => {
            _.values(page).forEach(d => {
                if (d.hasMonoColor) {
                    hasMonoColor = { ...hasMonoColor, [d.modelId]: true }
                }
            })
        })

        // await writeDoc(`products/${productId}`, JSON.parse(JSON.stringify({
        //     canvasJson: JSON.stringify(ToJson()),
        //     backgroundImageSrcsPage,
        //     lockedItemIds: lockedItemIds.length === 0 ? [''] : lockedItemIds,
        //     modelsPage,
        //     selectedModelIdPage,
        //     selectedBgIndex,
        //     boundaries,
        //     selectedPersonalisationPriceId,
        //     pageIds,
        //     pageNames,
        //     pageImageUrls,
        //     hasMonoColor,
        //     colors: colors.length === 0 ? null : colors,
        //     hasImages,
        // })));

        await writeRTDB(`products/${productId}`, JSON.parse(JSON.stringify({
            canvasJson: JSON.stringify(ToJson()),
            backgroundImageSrcsPage,
            lockedItemIds: lockedItemIds.length === 0 ? [''] : lockedItemIds,
            modelsPage,
            selectedModelIdPage,
            selectedBgIndex,
            boundaries,
            selectedPersonalisationPriceId,
            pageIds,
            pageNames,
            pageImageUrls,
            hasMonoColor,
            colors: colors.length === 0 ? null : colors,
            hasImages,
        })));

        setSaving(false);
    }

    return <EditProductContext.Provider value={{
        productId,
        setProductId,
        product,
        selectedBgIndex: selectedBgIndex,
        setSelectedBgIndex: setSelectedBgIndex,
        addText,
        texts,
        textIds,
        changeText,
        textTitles,
        textPlaceholders: textPlaceHolders,
        changeTextPlaceholder,
        changeTextTitles,
        changeBackgoundImage,
        save,
        saving,
        bgUploading,
        modelUploading,
        removeText,
        firestoreData,

        addModel,
        models,
        selectedModelId,
        selectModel,
        updateModelData,
        toggleLockedItem,
        lockedItemIds,
        isLocked,
        backgroundImageSrcs,
        setIsAdmin,
        isAdmin,
        userChangeText,
        userChangeTextById,
        hasBoundary,
        toggleBoundaryItem,
        boundaries,
        updateItemBoundary,
        changeTextById,
        selectedPersonalisationPriceId,
        setSelectedPersonalisationPriceId,
        personalisationPrices,

        pageIds,
        pageNames,
        selectedPageId,
        changePageName,
        addPage,
        removePage,
        pageImageUrls,
        changePageImage,
        uploadingPageImage,
        selectedPageIndex,
        nextPage,
        prevPage,
        changePage,

        removeModel,

        // hasMonoColor,
        // color,
        // setHasMonoColor,
        // changeColor,
        // resetColor

        colors,
        hasMonoColor,
        changeColor,
        resetColor,
        setHasMonoColor,

        changeMaterial,

        undoRedoHistory,
        undoRedoIndex,
        addUndoRedo,
        undo,
        redo,
        canRedo,
        canUndo,

        // hasImages,
        turnOnImage,
        turnOffImage,
        askHasImage,
        updateUserImageBoundary,
        getImageBoundaryId,

        applyColor,

        readyToDownload,
        setReadyToDownload,
        userRemoveText,
        userRemoveTextLS,
        isCanvasLoaded,
        userAddText,
        loading
    }}>
        {children}
    </EditProductContext.Provider>
}

export default EditProductContextProvider;