import React from 'react'
import { KOOL_GREEN, StyleSheet } from '../../const'
import { Section } from '../../contexts/UserSelectionContext'
import ModelsSelection from './ModelsSelection'
import MaterialSelection from './MaterialSelection'
import { ModelPanel } from '.'
import UserCustomizeGeneral from './UserCustomizeGeneral'
import UserCustomizeAddToCart from './UserCustomizeAddToCart'

interface ISampleComponent {
    selectedSection: Section;
    setSelectedSection: Function;
}

const MobileEtapes: React.FC<ISampleComponent> = (props) => {
    const {
        selectedSection,
        setSelectedSection
    } = props

    return (
        <div style={style.container}>
            <div style={style.etapes}>
                <div onClick={() => setSelectedSection(Section.MODEL)} style={{ ...style.etape, ...(selectedSection === Section.MODEL ? style.selected : {}) }}>Couleur</div>
                <div onClick={() => setSelectedSection(Section.TEXT)} style={{ ...style.etape, ...(selectedSection === Section.TEXT ? style.selected : {}) }}>Modèle</div>
                <div onClick={() => setSelectedSection(Section.GENERAL)} style={{ ...style.etape, ...(selectedSection === Section.GENERAL ? style.selected : {}) }}>Finale</div>
            </div>
            <div style={style.etapeSection}>
                <div style={{ flex: 1 }} />
                <div style={{ display: 'flex', width: '100vw', justifyContent: 'center' }}>
                    {selectedSection === Section.MODEL && <MaterialSelection hideLabels />}
                    {selectedSection === Section.TEXT && <ModelPanel mobile />}
                    {selectedSection === Section.GENERAL && <UserCustomizeGeneral mobile />}
                </div>
                <div style={{ flex: 1 }} />
                {/* <div style={{ height: 80 }} /> */}
                <div style={{ height: 80, width: '100vw' }}>
                    <UserCustomizeAddToCart mobile />
                </div>
            </div>
        </div>
    )
}

const style: StyleSheet = {
    container: {
        height: 280,
        display: 'flex',
        flexDirection: 'column'
    },
    etapes: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center',
    },
    etape: {
        fontSize: 24,
        fontWeight: 600,
        opacity: .333,
        cursor: 'pointer'
    },
    selected: {
        opacity: 1,
        backgroundColor: KOOL_GREEN,
        padding: `8px 16px`,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        overflow: 'hidden'
    },
    etapeSection: {
        backgroundColor: KOOL_GREEN,
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative'
    }
}

export default MobileEtapes
