import React from 'react';
import { useContext } from 'react';
import { MakeModelImageVisible } from '../../components/Customize/Customize';
import { EditProductContext, IUndoRedoType } from '../../contexts/EditProductContext';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, CircularProgress, FormControlLabel, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '../../components/Button/button';
import * as _ from 'lodash';
import EditModelContextProvider from '../../contexts/EditModelContext';
import EditModel from '../../components/Models/EditModel';


const EditModels = () => {
    const {
        addModel,
        models,
        selectModel,
        selectedModelId,
        addUndoRedo,
        addPage
    } = useContext(EditProductContext);

    const onSelectModel = (modelId: string) => {
        selectModel(modelId);
        addUndoRedo({
            type: IUndoRedoType.ModelChange,
            modelId: selectedModelId
        }, {
            type: IUndoRedoType.ModelChange,
            modelId
        });
    }

    return (
        <div>
            {_.values(models).map(model => (
                <EditModelContextProvider id={model.modelId} key={model.modelId}>
                    <Accordion>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <FormControlLabel
                                onClick={(event) => event.stopPropagation()}
                                onFocus={(event) => event.stopPropagation()}
                                control={<Checkbox color="primary" onClick={() => onSelectModel(model.modelId)} checked={model.modelId === selectedModelId} />}
                                label={model.modelName || 'Modélé'}
                            />
                        </AccordionSummary>
                        <AccordionDetails style={{ width: '100%' }}>
                            <EditModel />
                        </AccordionDetails>
                    </Accordion>
                </EditModelContextProvider>
            ))}

            <div style={{ marginTop: 16 }} />

            <Button name="Ajouter un modèle" onclick={addModel} />
        </div>
    );
}

export default EditModels;
