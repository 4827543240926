import React, { useState, useEffect } from "react"
import { DataGrid, GridRowId } from "@material-ui/data-grid"
import "./style.scss"
import { useFirestoreCollection } from "../../../firebase/firebase"
import { Button } from "@material-ui/core"

const Table = ({ data }) => {
  const [selectedModels, setSelectedModels] = useState<GridRowId[]>([])
  const {deleteRecord, loading: loadingRec} = useFirestoreCollection(['fonts'])

  const status = params => {
    return (
      <div
        className="status"
      >
        <Button onClick={() => deleteRecord(params.id)}>Delete</Button>
      </div>
    )
  }

  const FontText = params => {
    return (
      <>      
        <p style={{fontFamily: `'` + params.value + `'`, fontSize: '36px'}}>{params.value}</p>
      </>
    )
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.8,
      cellClassName: "num-id",
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
      renderCell: FontText,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "filename",
      headerName: "Filename",
      flex: 1,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
      renderCell: status,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
  ]

  return (
    <div style={{ height: "80vh" }}>
      <DataGrid
        className="model-table"
        rowHeight={180}
        pageSize={4}
        columns={columns}
        rows={data}
        onSelectionModelChange={(newSelection: any) => {
          setSelectedModels(newSelection.selectionModel)
        }}
        selectionModel={selectedModels}
        checkboxSelection
      />
    </div>
  )
}
export default Table
