import * as React from "react"

export const MonZayaLogo = props => {
  const { width, height } = props
  return (
    <svg
      width={width || 232}
      height={height || 56}
      viewBox="0 0 232 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M127.847 12.841h-13.769v-.483h14.558l-16.6 32.82h18.598v.483h-19.387l16.6-32.82z"
        fill="#33A3B6"
      />
      <path
        d="M130.876 45.904h-20.025l16.6-32.824h-13.619v-.965h15.197l-16.6 32.82h18.447v.968zm-19.236-.483h18.749-18.749zm16.6-32.823l-7.703 15.228 7.703-15.228zM22.592 13.902v27.9h-.487V11.965l12.32 22.937.046.146h.1l.048-.146 12.316-22.924v33.788h-.487V13.912l-11.93 22.202-11.926-22.212z"
        fill="#33A3B6"
      />
      <path
        d="M47.18 46.007h-.974v-31.13L34.522 36.63 22.837 14.868v27.174h-.974V11l12.662 23.576L47.183 11.01v34.997h-.003zm-.487-.483v-32.58l-11.86 22.075 11.86-22.072v32.577zm-12.34-10.232l.169.31.168-.31h-.336zM22.35 12.934L34.2 35.002l-.008-.02L22.35 12.934zM87.962 22.158v4.344c.55-1.414 1.467-2.573 2.75-3.475 1.282-.902 2.716-1.351 4.307-1.351 2.011 0 3.774.732 5.282 2.197 1.511 1.464 2.263 3.225 2.263 5.285v16.653h-.487V29.155c0-1.93-.698-3.578-2.092-4.946-1.397-1.368-3.052-2.05-4.966-2.05-1.947 0-3.613.682-4.99 2.05-1.38 1.368-2.067 3.015-2.067 4.946v16.653h-.487v-23.65h.487z"
        fill="#33A3B6"
      />
      <path
        d="M102.812 46.05h-.973V29.154c0-1.854-.682-3.462-2.022-4.777-1.343-1.314-2.954-1.98-4.794-1.98-1.874 0-3.492.666-4.819 1.98-1.326 1.315-1.997 2.923-1.997 4.777v16.892h-.974V21.914h.974v3.502a7.86 7.86 0 012.367-2.59 7.597 7.597 0 014.449-1.394c2.068 0 3.905.762 5.452 2.266 1.552 1.505 2.337 3.342 2.337 5.46V46.05zm-.487-.483v-16.41 16.41zm-14.605 0v-16.41c0-.772.107-1.504.326-2.193l-.326.835v-5.402 23.17zm7.303-23.653c.104 0 .204.004.305.007a8.23 8.23 0 00-.57 0c.09-.003.177-.007.265-.007zM139.823 24.887c-2.337 2.313-3.505 5.089-3.505 8.33 0 3.146 1.175 5.903 3.529 8.262 2.353 2.36 5.154 3.539 8.397 3.539 2.337 0 4.486-.623 6.45-1.871 1.964-1.245 3.415-2.86 4.358-4.84l.437.147c-.944 2.06-2.428 3.748-4.456 5.07-2.028 1.317-4.291 1.976-6.792 1.976-3.442 0-6.37-1.188-8.787-3.571-2.418-2.38-3.626-5.276-3.626-8.688 0-3.412 1.208-6.314 3.626-8.71 2.417-2.397 5.345-3.595 8.787-3.595 2.693 0 5.154.788 7.377 2.366 2.222 1.578 3.74 3.748 4.553 6.518v-8.402h.486v23.65h-.486V33.24c0-3.152-1.176-5.911-3.529-8.278-2.354-2.367-5.154-3.548-8.398-3.548-3.179.003-5.986 1.158-8.421 3.472z"
        fill="#33A3B6"
      />
      <path
        d="M148.245 45.743c-3.492 0-6.507-1.224-8.958-3.64-2.455-2.417-3.701-5.4-3.701-8.861 0-3.462 1.246-6.451 3.701-8.884 2.454-2.434 5.469-3.669 8.961-3.669 2.733 0 5.261.813 7.518 2.41 1.88 1.332 3.277 3.106 4.167 5.28v-7.204h.973v24.136h-.973V33.24c0-3.072-1.165-5.801-3.459-8.108-2.296-2.307-5.063-3.475-8.226-3.475-3.102 0-5.876 1.145-8.253 3.398-2.276 2.254-3.432 5-3.432 8.162 0 3.066 1.166 5.788 3.459 8.092 2.293 2.3 5.06 3.468 8.226 3.468 2.28 0 4.405-.615 6.319-1.83 1.914-1.215 3.351-2.81 4.268-4.74l.09-.193.9.296-.111.243c-.957 2.09-2.484 3.831-4.542 5.17-2.059 1.34-4.389 2.02-6.927 2.02zm-12.172-12.648v.147c0 3.328 1.196 6.194 3.556 8.517 2.357 2.324 5.258 3.502 8.616 3.502 2.441 0 4.68-.653 6.658-1.94a12.73 12.73 0 002.162-1.761 12.635 12.635 0 01-2.239 1.794 12.113 12.113 0 01-6.585 1.907c-3.3 0-6.184-1.215-8.572-3.612-2.39-2.396-3.599-5.232-3.599-8.43.003-.04.003-.08.003-.124zm12.172-11.92c3.297 0 6.181 1.219 8.572 3.619 2.364 2.373 3.572 5.179 3.599 8.34v-1.627l-.477-1.624c-.792-2.7-2.293-4.85-4.459-6.388-2.172-1.537-4.606-2.32-7.235-2.32-3.358 0-6.256 1.185-8.616 3.525-1.914 1.894-3.062 4.141-3.428 6.697.369-2.536 1.524-4.78 3.448-6.683l.004-.004c2.471-2.343 5.362-3.535 8.592-3.535zM172.409 21.425l7.051 22.77 7.021-22.77.511-.007-10.419 33.306-.464.02 3.069-9.98-7.303-23.346.534.007z"
        fill="#33A3B6"
      />
      <path
        d="M175.777 55l3.149-10.235-7.383-23.593 1.048.014.053.166 6.816 22.015 6.84-22.185 1.024-.013-.098.316-10.469 33.465-.98.05zm-3.573-33.335l2.173 6.943-2.173-6.943zM197.915 24.887c-2.337 2.313-3.506 5.089-3.506 8.33 0 3.146 1.175 5.903 3.529 8.262 2.354 2.36 5.154 3.539 8.397 3.539a11.79 11.79 0 006.451-1.871c1.964-1.245 3.414-2.86 4.358-4.84l.436.147c-.943 2.06-2.427 3.748-4.455 5.07-2.028 1.317-4.291 1.976-6.793 1.976-3.441 0-6.369-1.188-8.787-3.571-2.417-2.38-3.626-5.276-3.626-8.688 0-3.412 1.209-6.314 3.626-8.71 2.418-2.397 5.346-3.595 8.787-3.595 2.693 0 5.154.788 7.377 2.366s3.74 3.748 4.553 6.518v-8.402h.487v23.65h-.487V33.24c0-3.152-1.175-5.911-3.529-8.278-2.354-2.367-5.154-3.548-8.398-3.548-3.176.003-5.986 1.158-8.42 3.472z"
        fill="#33A3B6"
      />
      <path
        d="M206.338 45.743c-3.491 0-6.507-1.224-8.958-3.64-2.454-2.417-3.7-5.4-3.7-8.861 0-3.462 1.246-6.451 3.7-8.884 2.455-2.434 5.47-3.669 8.962-3.669 2.733 0 5.261.813 7.518 2.41 1.88 1.332 3.277 3.106 4.166 5.28v-7.204H219v24.136h-.974V33.24c0-3.072-1.165-5.801-3.458-8.108-2.297-2.307-5.063-3.475-8.226-3.475-3.103 0-5.876 1.145-8.253 3.398-2.277 2.254-3.432 5-3.432 8.162 0 3.066 1.165 5.788 3.459 8.092 2.293 2.3 5.06 3.468 8.226 3.468 2.28 0 4.405-.615 6.319-1.83 1.914-1.215 3.351-2.81 4.271-4.74l.09-.193.9.296-.11.243c-.957 2.09-2.485 3.831-4.543 5.17-2.065 1.34-4.396 2.02-6.931 2.02zm-12.171-12.648v.147c0 3.328 1.195 6.194 3.556 8.517 2.357 2.324 5.258 3.502 8.615 3.502 2.441 0 4.681-.653 6.659-1.94a12.77 12.77 0 002.162-1.761 12.607 12.607 0 01-2.24 1.794 12.107 12.107 0 01-6.584 1.907c-3.3 0-6.185-1.215-8.572-3.612-2.391-2.396-3.599-5.232-3.599-8.43.003-.04.003-.08.003-.124zm12.171-11.92c3.298 0 6.182 1.219 8.573 3.619 2.363 2.373 3.572 5.179 3.599 8.34v-1.627l-.477-1.624c-.792-2.7-2.293-4.85-4.459-6.388-2.172-1.537-4.606-2.32-7.236-2.32-3.357 0-6.255 1.185-8.615 3.525-1.914 1.894-3.062 4.141-3.428 6.697.369-2.536 1.524-4.78 3.448-6.683l.003-.004c2.472-2.343 5.362-3.535 8.592-3.535z"
        fill="#33A3B6"
      />
      <path
        d="M65.918 20.379l1.478 11.024-1.894-10.964.416-.06zM63.693 20.893l3.387 10.601-3.784-10.472.397-.13zM61.594 21.777l5.194 9.86-5.563-9.66.369-.2zM59.68 23.012l6.843 8.814-7.168-8.555.325-.26zM58.012 24.56l8.283 7.502-8.559-7.193.276-.31zM56.642 26.367l9.471 5.965-9.69-5.609.219-.356zM55.608 28.388l10.371 4.24-10.523-3.854.152-.386zM54.942 30.552l10.96 2.393-11.04-1.987.08-.406zM54.667 32.798l11.212.47-11.218-.054.006-.416zM54.791 35.058l11.121-1.468-11.057 1.877-.063-.41zM55.309 37.262l10.694-3.359-10.56 3.752-.134-.393zM56.202 39.346l9.945-5.15-9.744 5.512-.201-.362zM57.45 41.243l8.892-6.784-8.633 7.106-.258-.322zM59.009 42.894l7.568-8.209-7.253 8.485-.315-.276zM60.835 44.255l6.014-9.39-5.658 9.606-.356-.216zM62.87 45.28l4.277-10.282L63.26 45.43l-.39-.15zM65.053 45.935l2.414-10.86-2.001 10.944-.413-.084zM67.319 46.208l.477-11.11-.057 11.12-.42-.01zM69.599 46.089l-1.477-11.027 1.893 10.964-.416.063zM71.825 45.577l-3.388-10.605 3.784 10.471-.396.134zM73.923 44.687l-5.194-9.859 5.564 9.663-.37.196zM75.837 43.453l-6.843-8.814 7.169 8.554-.326.26zM77.506 41.909l-8.283-7.503 8.558 7.19-.275.313zM78.876 40.097l-9.472-5.961 9.69 5.608-.218.353zM79.91 38.08l-10.372-4.243 10.523 3.858-.151.386zM80.575 35.914l-10.96-2.39 11.04 1.984-.08.406zM80.85 33.667l-11.211-.47 11.22.057-.01.413zM80.726 31.407l-11.12 1.467 11.056-1.877.064.41zM80.209 29.202l-10.694 3.359 10.56-3.751.134.392zM79.316 27.12l-9.945 5.149 9.744-5.512.2.363zM78.067 25.225l-8.891 6.784 8.632-7.107.259.323zM76.509 23.57l-7.569 8.212 7.253-8.484.316.273zM72.648 21.188L68.37 31.47l3.888-10.432.39.15zM70.465 20.528L68.05 31.39l2-10.94.414.08zM68.198 20.256l-.476 11.114.057-11.124.42.01z"
        fill="#F9BA44"
        stroke="#F9BA44"
        strokeWidth={0.5}
        strokeMiterlimit={10}
      />
      <path
        d="M43.076 45.787a.231.231 0 01-.231-.23V19.612a.231.231 0 01.463 0v25.947c.003.126-.1.23-.232.23z"
        fill="#33A3B6"
      />
      <path
        d="M43.076 46.03a.474.474 0 01-.476-.473V19.611a.477.477 0 01.953 0v25.946c0 .26-.211.473-.477.473z"
        fill="#33A3B6"
      />
      <path
        d="M50.796 45.94H38.282a.231.231 0 01-.232-.23c0-.126.104-.23.232-.23h12.514c.127 0 .232.104.232.23 0 .127-.105.23-.232.23z"
        fill="#33A3B6"
      />
      <path
        d="M50.796 46.183H38.282a.477.477 0 01-.477-.473c0-.26.215-.473.477-.473h12.514c.262 0 .477.213.477.473s-.215.473-.477.473zM17.246 45.939a.205.205 0 01-.205-.193.21.21 0 01.195-.22c.171-.01 4.227-.32 4.916-4.48a.204.204 0 01.238-.17c.114.02.188.123.171.236-.742 4.487-5.254 4.824-5.298 4.824-.01.003-.013.003-.017.003z"
        fill="#33A3B6"
      />
      <path
        d="M17.246 46.182a.45.45 0 01-.45-.42.443.443 0 01.42-.472c.164-.01 4.032-.303 4.69-4.28a.45.45 0 01.813-.19c.07.096.097.216.077.333-.775 4.697-5.476 5.023-5.523 5.026l-.027.003zm0-.482v.243-.243z"
        fill="#33A3B6"
      />
      <path
        d="M27.46 45.94h-.013c-.047-.004-4.556-.337-5.298-4.823a.204.204 0 01.17-.237c.112-.02.22.057.24.17.691 4.17 4.875 4.477 4.915 4.48a.206.206 0 01.195.216.206.206 0 01-.209.194z"
        fill="#33A3B6"
      />
      <path
        d="M27.46 46.183l-.05-.004c-.023 0-4.724-.329-5.503-5.022a.448.448 0 01.373-.513.442.442 0 01.332.077c.1.07.165.173.185.293.654 3.954 4.65 4.277 4.69 4.28.121.007.229.06.31.15.08.09.12.203.11.323a.442.442 0 01-.447.416zm-5.143-5.096l-.168.026.168-.026z"
        fill="#33A3B6"
      />
      <path
        d="M29.22 45.94H15.45a.208.208 0 01-.208-.206c0-.114.094-.207.208-.207h13.77c.114 0 .208.093.208.207a.206.206 0 01-.208.206z"
        fill="#33A3B6"
      />
      <path
        d="M29.22 46.183H15.45c-.248 0-.45-.2-.45-.446s.201-.446.45-.446h13.77c.248 0 .45.2.45.446a.448.448 0 01-.45.446zM132.384 12.245L115.676 45.07l.434.218 16.709-32.826-.435-.217z"
        fill="#33A3B6"
      />
      <path
        d="M115.891 45.42a.228.228 0 01-.111-.026.24.24 0 01-.108-.323l16.647-32.64a.247.247 0 01.33-.106.24.24 0 01.107.323l-16.647 32.64a.25.25 0 01-.218.133z"
        fill="#33A3B6"
      />
      <path
        d="M115.891 45.66a.482.482 0 01-.434-.699l16.648-32.64a.476.476 0 01.282-.24.482.482 0 01.587.676l-16.647 32.64a.49.49 0 01-.436.263zm16.643-33.122l-16.647 32.64v.243-.243l16.647-32.64z"
        fill="#33A3B6"
      />
    </svg>
  )
}

export const DashBoardIcon = props => {
  const { width, height } = props
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x={3}
        y={3}
        width={18}
        height={18}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 13H4c-.55 0-1-.45-1-1V4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v8c0 .55-.45 1-1 1zm0 8H4c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1zm4 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 8V4c0-.55.45-1 1-1h6c.55 0 1 .45 1 1v4c0 .55-.45 1-1 1h-6c-.55 0-1-.45-1-1z"
          fill="#000"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path fill="#354530" d="M0 0h24v24H0z" />
      </g>
    </svg>
  )
}

export const LaModelIcon = (props) => {
    const {width, height} = props;
  return (
    <svg
      width={width || 18}
      height={height || 18}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="-3" y="-3" width="24" height="24" fill="#A5ABB3" />
    </svg>
  )
}
