import React, { useContext, useRef, useState } from 'react';
import { EditModelContext } from '../../../contexts/EditModelContext';
import Button from '../../Button/button';
import { CircularProgress, FormControlLabel, TextField } from '@material-ui/core';
import './style.scss';
import Input from '../../Input/Input';
import EditText from './EditText';
import Lock from '../../Lock';
import { EditProductContext } from '../../../contexts/EditProductContext';
import Boundary from '../../Boundary';
import EditModelColor from './EditModelColor';
import { Button as MaterialButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';


const EditModel = () => {
    const {
        modelId,
        modelImageUrl,
        modelName,
        setModelName,
        uploading,
        addModelImage,
        modelImageId,

        previewImageUrl,
        changePreviewImage,
        uploadingPreview,

        categoryName,
        setCategoryName
    } = useContext(EditModelContext);
    const {
        toggleLockedItem,
        isLocked,
        toggleBoundaryItem,
        hasBoundary,
        removeModel
    } = useContext(EditProductContext);
    const inputActualImageRef = useRef(null);
    const inputPreviewImageRef = useRef(null);

    const onActualImageChange = (e: any) => {
        addModelImage(e.target.files[0]);
    }

    const onPreviewImageChange = (e: any) => {
        changePreviewImage(e.target.files[0]);
    }

    return (
        <div className="EditModel">
            <TextField
                label="Төрөл"
                InputLabelProps={{
                    shrink: true,
                }}
                style={{ marginTop: 12 }}
                variant="standard"
                value={categoryName}
                onChange={(e) => setCategoryName(e.target.value)}
            />

            <div className="EditModel-1st-col">
                <div className="EditModel-image" style={{ backgroundImage: `url(${modelImageUrl})` }}>
                    {uploading && <CircularProgress />}
                </div>
                {modelImageId && (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 12, width: '100%' }}>
                        <div style={{ cursor: 'pointer' }} onClick={() => toggleLockedItem(modelImageId)}>
                            <Lock locked={isLocked(modelImageId)} />
                        </div>
                        <div style={{ cursor: 'pointer' }} onClick={() => toggleBoundaryItem(modelImageId)}>
                            <Boundary hasBoundary={hasBoundary(modelImageId)} />
                        </div>
                    </div>
                )}
                <Button name={"Changer l'image"} disable={uploading} onclick={() => inputActualImageRef.current.click()}></Button>
                <input
                    ref={inputActualImageRef}
                    type="file"
                    style={{ position: 'absolute', left: -10000 }}
                    onChange={onActualImageChange}
                />

                <div className="EditModel-preview-image" style={{ backgroundImage: `url(${previewImageUrl})` }}>
                    {uploadingPreview && <div className="absolute-center"><CircularProgress /></div>}
                </div>

                <Button name={"Modifier l'image d'aperçu"} disable={uploading} onclick={() => inputPreviewImageRef.current.click()}></Button>
                <input
                    ref={inputPreviewImageRef}
                    type="file"
                    style={{ position: 'absolute', left: -10000 }}
                    onChange={onPreviewImageChange}
                />
            </div>

            <Input style={{ width: '100%' }} title={'Nom'} value={modelName} setValue={setModelName} placeholder="Nom" />

            <h3>Les textes</h3>

            <EditText />

            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 32 }}>
                <MaterialButton
                    variant="contained"
                    color="secondary"
                    startIcon={<DeleteIcon />}
                    onClick={() => removeModel(modelId)}
                >
                    Supprimer le modèle
                </MaterialButton>
            </div>
        </div>
    )
}

export default EditModel;
