import { Button, FormControl, Menu, MenuItem, MenuProps, Select, Slider, TextField, Theme, createStyles, makeStyles, withStyles } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { WindowResolutionContext } from '../../contexts/WindowResolutionContext'
import { FontContext } from '../../contexts/FontContext'
import Bold from './../../assets/icons/new/Bold.svg'
import Italie from './../../assets/icons/new/itali.svg'
import Usvg from './../../assets/icons/new/U-1.svg'
import Texte from './../../assets/icons/new/Texte.svg'
import AddText from './../../assets/icons/new/addd-texte.svg'
import DeleteText from './../../assets/icons/new/delete-text.svg'
import LeftAlign from './../../assets/icons/new/gauche-1.svg'
import CenterAlign from './../../assets/icons/new/centre-1.svg'
import RightAlign from './../../assets/icons/new/droite-1.svg'
import { Def } from '../../components/Customize/Customize'
import { MainContext } from '../../contexts/MainContext'
import { EditProductContext } from '../../contexts/EditProductContext'
import * as _ from 'lodash'

interface ITextModifierSettings {
}


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        formControl: {
            margin: theme.spacing(1),
            minWidth: 150,
            padding: 0,
            // maxHeight: 20,
        },
        selectEmpty: {
            marginTop: theme.spacing(1),
        },
        input: {
            width: 42,
        },
    })
)

const StyledMenu = withStyles({
    paper: {
        width: '353px',
        border: '1px solid #DADEE3',
        boxSizing: 'border-box',
        borderRadius: '4px',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "left"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left",
        }}
        {...props}
    />
))

const StyledMenuItem = withStyles(theme => ({
    root: {
    },
}))(MenuItem)

const MenuPropsCustom = {
    PaperProps: {
        style: {
            maxHeight: 400
        },
    },
};

const TextModifierSettings: React.FC<ITextModifierSettings> = (props) => {
    const {
    } = props

    const classes = useStyles()

    const { isMobile } = useContext(WindowResolutionContext)
    const { fonts, getUrl: getFontUrl } = useContext(FontContext)

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
    const [alignmentAnchor, setAlignmentAnchor] = React.useState<null | HTMLElement>(null)

    const [textSelected, setTextSelected] = useState(false)

    const [maxTextLines, setMaxTextLines] = useState(-1);

    const {
        isCanvasLoaded,
        userAddText,
        selectedModelId,
        userRemoveText,
        models
    } = useContext(EditProductContext)

    const { texts = [], textPlaceHolders = [], textTitles = [], textCharacterLimits = [] } = _.get(models, [selectedModelId], {});

    const {
        values,
        setValue
    } = useContext(MainContext)

    const fontHandler = e => {
        setValue({ ...values, 'fontFamily': e.target.value })
        const font = fonts.find(d => d.name === e.target.value);
        Def.changeStyle(4, { fontFamily: e.target.value, fontFileName: font.filename, fontUrl: font.url })
    }

    const handleSliderChange = (key, value) => {
        let x = value
        if (typeof value == 'string') {
            if (value != '') value = x = parseInt(value)
            else x = 0
        }

        if (key == 'charSpacing') Def.changeStyle(7, x);
        if (key == 'lineSpace') Def.changeStyle(8, x);
        if (key == 'angle') Def.changeStyle(9, x);
        setValue({ ...values, [key]: value })
    }

    const [maxTextLinesByModels, setMaxTextLinesByModels] = useState<any>({})

    useEffect(() => {
        if (isCanvasLoaded && maxTextLinesByModels[selectedModelId] == null) {
            // if (maxTextLines < 1) {
                // setMaxTextLines(texts.length)
                setMaxTextLinesByModels(prev => ({...prev, [selectedModelId]: texts.length}))
            // }
            Def.canvas.on('selection:created', () => setTextSelected(true))
            Def.canvas.on('selection:cleared', (e) => {
                setTextSelected(false)
            })
        }
    }, [isCanvasLoaded, maxTextLines, selectedModelId])
    
    useEffect(() => {
        localStorage.setItem('textsVisible', JSON.stringify(texts))
    }, [JSON.stringify(texts)])

    const requestAddText = () => {
        if (texts.length >= maxTextLinesByModels[selectedModelId]) {
            return
        }
        userAddText(selectedModelId)
    }

    const requestRemoveText = () => {
        if (texts.length < 2) {
            return
        }
        const activeObj = Def.canvas.getActiveObject()
        if (activeObj && activeObj.id.includes('text')) {
            const textIndex = texts.findIndex(s => s === activeObj.text)
            userRemoveText(selectedModelId, textIndex)
        }
    }

    if (isMobile) {
        return (
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', zIndex: 10000 }}>
                <FormControl className={classes.formControl} variant="outlined" style={{ width: 140, opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }}>
                    <Select
                        MenuProps={MenuPropsCustom}
                        className='custom-select'
                        id="demo-simple-select"
                        value={values.fontFamily}
                        onChange={fontHandler}
                        defaultValue='default'
                        style={{ width: '100%', fontFamily: (values.fontFamily != 'default' ? values.fontFamily : null) }}
                    >
                        {fonts.map(value => {
                            return (
                                <MenuItem
                                    key={value.name}
                                    className='custom-option'
                                    value={value.name}
                                    style={{ fontStyle: "normal", fontFamily: (value.name != 'default' ? value.name : null) }}
                                >
                                    {value.name}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <div style={{ display: 'flex', alignItems: 'center', position: 'relative', zIndex: 222222 }}>
                    <Button onClick={(e) => setAnchorEl(e.currentTarget)} style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }}>
                        <img style={{ height: 16 }} src={Texte} />
                    </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={() => {
                            setAnchorEl(null);
                        }}
                        PaperProps={isMobile ? { style: { transform: 'scale(0.75, 0.5625)', top: 0, zIndex: 22222222222, opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', } } : { style: { top: 0 } }}
                    // PaperProps={{ style: { top: 0 } }}
                    >
                        <StyledMenuItem style={{ display: 'flex', alignItems: 'center', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }} dense={isMobile}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                                <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Courbe</p>
                                <Slider
                                    id='angle'
                                    name='angle'
                                    min={-360}
                                    max={360}
                                    style={{ width: '90%' }}
                                    value={values.angle}
                                    onChange={(e, num) => handleSliderChange('angle', num)}
                                    aria-labelledby="angle-slider"
                                />
                                <div style={{ marginLeft: '4px' }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={values.angle}
                                        onChange={(e) => handleSliderChange('angle', e.target.value)}
                                        type='number'
                                        style={{ height: 5 }}
                                        inputProps={{
                                            step: 'none',
                                            min: -360,
                                            max: 360,
                                            type: 'number',
                                            style: { height: isMobile ? 5 : 10 },
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                </div>
                            </div>
                        </StyledMenuItem>
                        <StyledMenuItem style={{ display: 'flex', alignItems: 'center', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }} dense={isMobile}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                                <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Interligne</p>
                                <Slider
                                    name='lineSpce'
                                    min={-20}
                                    max={20}
                                    style={{ width: '90%' }}
                                    value={values.lineSpace}
                                    onChange={(e, num) => handleSliderChange('lineSpace', num)}
                                    aria-labelledby="input-slider"
                                />
                                <div style={{ marginLeft: '4px' }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        value={values.lineSpace}
                                        onChange={(e) => handleSliderChange('lineSpace', e.target.value)}
                                        type='number'
                                        inputProps={{
                                            step: 10,
                                            min: 0,
                                            max: 500,
                                            type: 'number',
                                            style: { height: isMobile ? 5 : 10 },
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                </div>
                            </div>

                        </StyledMenuItem>
                        <StyledMenuItem style={{ display: 'flex', alignItems: 'center', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }} dense={isMobile}>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                                <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Espace</p>
                                <Slider
                                    name='charSpacing'
                                    style={{ width: '90%' }}
                                    value={values.charSpacing}
                                    onChange={(e, num) => handleSliderChange('charSpacing', num)}
                                    aria-labelledby="input-slider"
                                />
                                <div style={{ marginLeft: '4px' }}>
                                    <TextField
                                        variant="outlined"
                                        size="small"
                                        style={{ marginLeft: '4px !important' }}
                                        value={values.charSpacing}
                                        onChange={(e) => handleSliderChange('charSpacing', e.target.value)}
                                        type='number'
                                        inputProps={{
                                            step: 10,
                                            min: 0,
                                            max: 100,
                                            type: 'number',
                                            style: { height: isMobile ? 5 : 10 },
                                            'aria-labelledby': 'input-slider',
                                        }}
                                    />
                                </div>
                            </div>
                        </StyledMenuItem>
                    </StyledMenu>
                    <Button
                        style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }}
                        onClick={() => Def.changeStyle(1, "Bold")} // Def.changeStyle(1, "Bold")
                    >
                        <img style={{ height: 16 }} src={Bold} />
                    </Button>
                    <Button
                        style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }}
                        onClick={() => Def.changeStyle(2, "Italic")}
                    >
                        <img style={{ height: 16 }} src={Italie} />
                    </Button>
                    <Button
                        style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }}
                        onClick={() => Def.changeStyle(3, "Underline")}
                    >
                        <img style={{ height: 16 }} src={Usvg} />
                    </Button>

                    <Button onClick={(e) => setAlignmentAnchor(e.currentTarget)} style={{ minWidth: 0, transform: 'scale(1.2)', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }}>
                        <img style={{ height: 16 }} src={CenterAlign} />
                    </Button>
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={alignmentAnchor}
                        keepMounted
                        open={Boolean(alignmentAnchor)}
                        onClose={() => setAlignmentAnchor(null)}
                    >
                        <div style={{ display: 'flex', justifyContent: 'space-around', opacity: textSelected ? 1 : 0, pointerEvents: textSelected ? 'auto' : 'none', }}>
                            <Button onClick={() => Def.changeStyle(10, 'left')}> <img style={{ width: '23px' }} src={LeftAlign} /> </Button>
                            <Button onClick={() => Def.changeStyle(10, 'center')}> <img style={{ width: '23px' }} src={CenterAlign} /> </Button>
                            <Button onClick={() => Def.changeStyle(10, 'right')}><img style={{ width: '23px' }} src={RightAlign} /></Button>
                        </div>
                    </StyledMenu>
                    <Button onClick={() => requestRemoveText()} style={{ minWidth: 0, transform: 'scale(1.2)', opacity: isCanvasLoaded && textSelected ? .5 : 0 }}>
                        <img style={{ height: 16 }} src={DeleteText} />
                    </Button>
                    <Button onClick={() => requestAddText()} style={{ minWidth: 0, transform: 'scale(1.2)', opacity: (texts.length < maxTextLinesByModels[selectedModelId] ? .5 : 0) }}>
                        <img style={{ height: 16 }} src={AddText} />
                    </Button>
                </div>

            </div>
        )
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '0 20px' }}>
            <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                <Button onClick={(e) => setAnchorEl(e.currentTarget)} style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}>
                    <img style={{ height: 16 }} src={Texte} />
                </Button>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => {
                        setAnchorEl(null);
                    }}
                    // PaperProps={isMobile ? { style: { transform: 'scale(0.75, 0.5625)', marginLeft: 64 } } : {}}
                    PaperProps={{ style: { top: 0 } }}
                >
                    <StyledMenuItem style={{ display: 'flex', alignItems: 'center' }} dense={isMobile}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                            <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Courbe</p>
                            <Slider
                                id='angle'
                                name='angle'
                                min={-360}
                                max={360}
                                style={{ width: '90%' }}
                                value={values.angle}
                                onChange={(e, num) => handleSliderChange('angle', num)}
                                aria-labelledby="angle-slider"
                            />
                            <div style={{ marginLeft: '4px' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={values.angle}
                                    onChange={(e) => handleSliderChange('angle', e.target.value)}
                                    type='number'
                                    style={{ height: 5 }}
                                    inputProps={{
                                        step: 'none',
                                        min: -360,
                                        max: 360,
                                        type: 'number',
                                        style: { height: isMobile ? 5 : 10 },
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </div>
                        </div>
                    </StyledMenuItem>
                    <StyledMenuItem style={{ display: 'flex', alignItems: 'center' }} dense={isMobile}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                            <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Interligne</p>
                            <Slider
                                name='lineSpce'
                                min={-20}
                                max={20}
                                style={{ width: '90%' }}
                                value={values.lineSpace}
                                onChange={(e, num) => handleSliderChange('lineSpace', num)}
                                aria-labelledby="input-slider"
                            />
                            <div style={{ marginLeft: '4px' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    value={values.lineSpace}
                                    onChange={(e) => handleSliderChange('lineSpace', e.target.value)}
                                    type='number'
                                    inputProps={{
                                        step: 10,
                                        min: 0,
                                        max: 500,
                                        type: 'number',
                                        style: { height: isMobile ? 5 : 10 },
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </div>
                        </div>

                    </StyledMenuItem>
                    <StyledMenuItem style={{ display: 'flex', alignItems: 'center' }} dense={isMobile}>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
                            <p style={{ minWidth: '80px', marginTop: 0, marginBottom: 0 }}>Espace</p>
                            <Slider
                                name='charSpacing'
                                style={{ width: '90%' }}
                                value={values.charSpacing}
                                onChange={(e, num) => handleSliderChange('charSpacing', num)}
                                aria-labelledby="input-slider"
                            />
                            <div style={{ marginLeft: '4px' }}>
                                <TextField
                                    variant="outlined"
                                    size="small"
                                    style={{ marginLeft: '4px !important' }}
                                    value={values.charSpacing}
                                    onChange={(e) => handleSliderChange('charSpacing', e.target.value)}
                                    type='number'
                                    inputProps={{
                                        step: 10,
                                        min: 0,
                                        max: 100,
                                        type: 'number',
                                        style: { height: isMobile ? 5 : 10 },
                                        'aria-labelledby': 'input-slider',
                                    }}
                                />
                            </div>
                        </div>
                    </StyledMenuItem>
                </StyledMenu>
                <Button
                    style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}
                    onClick={() => Def.changeStyle(1, "Bold")} // Def.changeStyle(1, "Bold")
                >
                    <img style={{ height: 16 }} src={Bold} />
                </Button>
                <Button
                    style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}
                    onClick={() => Def.changeStyle(2, "Italic")}
                >
                    <img style={{ height: 16 }} src={Italie} />
                </Button>
                <Button
                    style={{ maxWidth: "30px", minWidth: "30px", marginLeft: "0", transform: 'scale(1.2)' }}
                    onClick={() => Def.changeStyle(3, "Underline")}
                >
                    <img style={{ height: 16 }} src={Usvg} />
                </Button>

                <Button onClick={(e) => setAlignmentAnchor(e.currentTarget)} style={{ minWidth: 0, transform: 'scale(1.2)' }}>
                    <img style={{ height: 16 }} src={CenterAlign} />
                </Button>
                <StyledMenu
                    id="customized-menu"
                    anchorEl={alignmentAnchor}
                    keepMounted
                    open={Boolean(alignmentAnchor)}
                    onClose={() => setAlignmentAnchor(null)}
                >
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Button onClick={() => Def.changeStyle(10, 'left')}> <img style={{ width: '23px' }} src={LeftAlign} /> </Button>
                        <Button onClick={() => Def.changeStyle(10, 'center')}> <img style={{ width: '23px' }} src={CenterAlign} /> </Button>
                        <Button onClick={() => Def.changeStyle(10, 'right')}><img style={{ width: '23px' }} src={RightAlign} /></Button>
                    </div>
                </StyledMenu>
            </div>
            <FormControl className={classes.formControl} variant="outlined" style={{ width: '100%' }}>
                <Select
                    MenuProps={MenuPropsCustom}
                    className='custom-select'
                    id="demo-simple-select"
                    value={values.fontFamily}
                    onChange={fontHandler}
                    defaultValue='default'
                    style={{ width: '100%', fontFamily: (values.fontFamily != 'default' ? values.fontFamily : null) }}
                >
                    {fonts.map(value => {
                        return (
                            <MenuItem
                                key={value.name}
                                className='custom-option'
                                value={value.name}
                                style={{ fontStyle: "normal", fontFamily: (value.name != 'default' ? value.name : null) }}
                            >
                                {value.name}
                            </MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    )
}


export default TextModifierSettings
