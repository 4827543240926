import React, { useContext, useEffect, useState } from 'react';
import { EditProductContext } from '../../../contexts/EditProductContext';
import { Section, UserSelectionContext } from '../../../contexts/UserSelectionContext';
import * as _ from 'lodash';
import './style.scss';
import AddedToCartDialog from './AddedToCartDialog';
import { addDoc, pushRTDB, putString, updateRTDB, useFirestoreDocument, writeDoc, writeRTDB } from '../../../firebase/firebase';
import { ToImage, ToJson, ToJsonUsedOnly } from '../../../components/Customize/Customize';
import { CircularProgress } from '@material-ui/core'
import { WindowResolutionContext } from '../../../contexts/WindowResolutionContext';
import OutOfStockDialog from './OutOfStockDialog';
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
import { DARK_GREEN } from '../../../const';

var browser = (function () {
    var test = function (regexp) { return regexp.test(window.navigator.userAgent) }
    switch (true) {
        case test(/edg/i): return "Microsoft Edge";
        case test(/trident/i): return "Microsoft Internet Explorer";
        case test(/firefox|fxios/i): return "Mozilla Firefox";
        case test(/opr\//i): return "Opera";
        case test(/ucbrowser/i): return "UC Browser";
        case test(/samsungbrowser/i): return "Samsung Browser";
        case test(/chrome|chromium|crios/i): return "Google Chrome";
        case test(/safari/i): return "Apple Safari";
        default: return "Other";
    }
})();

function roughSizeOfObject(object) {

    var objectList = [];
    var stack = [object];
    var bytes = 0;

    while (stack.length) {
        var value = stack.pop();

        if (typeof value === 'boolean') {
            bytes += 4;
        }
        else if (typeof value === 'string') {
            bytes += value.length * 2;
        }
        else if (typeof value === 'number') {
            bytes += 8;
        }
        else if
            (
            typeof value === 'object'
            && objectList.indexOf(value) === -1
        ) {
            objectList.push(value);

            for (var i in value) {
                stack.push(value[i]);
            }
        }
    }
    return bytes;
}


const UserCustomizeAddToCart = ({ onCloseSidebar = () => { }, mobile = false }) => {
    const {
        product,
        firestoreData,
        nextPage,
        selectedPageIndex,
        pageNames,
        selectedPersonalisationPriceId,
        personalisationPrices,
        setSelectedPersonalisationPriceId,
        askHasImage
    } = useContext(EditProductContext);
    const {
        material,
        modelName,
        quantity,
        generalOption,
        basePrice,
        totalAmount,
        selectedSection,
        setSelectedSection,
        sections,
        setIsSuccessDialogOpen,
        setIsOutOfStockDialogOpen,
        userImageUrls,
        hasFetchedAllData,
        fsId,
        hasAddedToShopifyCart,
        outOfStock,
        switchFsId
    } = useContext(UserSelectionContext);

    const [open, setOpen] = React.useState(false);
    const [addingToCart, setAddingToCart] = useState(true);
    const [error, setError] = useState<string>(null);

    const [win, setWin] = useState<any>(null);

    const { isMobile } = useContext(WindowResolutionContext);

    const isLastOfLastSection = selectedPageIndex === pageNames.length - 1 && selectedSection === _.last(sections);
    const isFirstSection = selectedSection === _.first(sections);
    const selectedSectionIndex = _.findIndex(sections, d => d == selectedSection);

    const hasImages = askHasImage();


    useEffect(() => {
        if (hasFetchedAllData && fsId) {
            setAddingToCart(false);
        }
    }, [hasFetchedAllData, fsId]);

    useEffect(() => {
        if (outOfStock) {
            setIsOutOfStockDialogOpen(true);
            setAddingToCart(false);
        } else if (hasAddedToShopifyCart) {
            setIsSuccessDialogOpen(true);
            setAddingToCart(false);
        }
    }, [hasAddedToShopifyCart, outOfStock]);

    const nextSection = () => {
        if (isLastOfLastSection) {
            return;
        }

        if (selectedSection === _.last(sections)) {
            nextPage();
        }
        const index = _.findIndex(sections, d => d === selectedSection);
        if (!hasImages && sections[(index + 1) % sections.length] === Section.IMAGES) {
            setSelectedSection(sections[(index + 2) % sections.length]);
        } else {
            setSelectedSection(sections[(index + 1) % sections.length]);
        }
    }

    const prevSection = () => {
        if (isFirstSection) {
            return;
        }

        const index = _.findIndex(sections, d => d === selectedSection);
        if (!hasImages && sections[(index - 1) % sections.length] === Section.IMAGES) {
            setSelectedSection(sections[(index - 2) % sections.length]);
        } else {
            setSelectedSection(sections[(index - 1) % sections.length]);
        }
    }

    const isSafari = browser === "Apple Safari";

    const addToCart = async (event) => {
        if (addingToCart) {
            return;
        }

        setAddingToCart(true);
        setError(null);

        const { variants } = product;
        const firstAvailableVariant = _.find(variants, d => d.inventory_quantity > 0 && d.title.split(' / ').includes(material) && d.title.split(' / ').includes(generalOption));

        if (!firstAvailableVariant) {
            setAddingToCart(false);
            setError(`${material} / ${generalOption} / ${modelName} est épuisé`);
            return;
        }

        // await writeDoc(`addedToCart/${fsId}`, {
        //     canvasJson: JSON.stringify(ToJson()), 
        //     verified: false,
        //     hasImages,
        //     userImageUrls: userImageUrls.length === 0 ? null : userImageUrls,
        //     quantity,
        //     material,
        //     modelName,
        //     generalOption,
        //     variantId: firstAvailableVariant.id
        // });

        var started = Date.now();

        const data64 = await ToImage();
        const previewImageUrl = await putString(`cartItemImages/${fsId}`, data64);

        await writeRTDB(`addedToCart/${fsId}`, {
            canvasJson: JSON.stringify(await ToJsonUsedOnly()),
            verified: false,
            hasImages,
            userImageUrls: userImageUrls.length === 0 ? null : userImageUrls,
            quantity,
            material,
            modelName,
            generalOption,
            variantId: firstAvailableVariant.id,
            previewImageUrl
        });

        var end = Date.now();
    }

    function chunkString(str, len) {
        const size = Math.ceil(str.length / len)
        const r = Array(size)
        let offset = 0

        for (let i = 0; i < size; i++) {
            r[i] = str.substr(offset, len)
            offset += len
        }

        return r
    }

    const popup = (url, windowName, cb) => {
        const win: Window = window.open(url, windowName, 'height=300,width=300');

        const interval = setInterval(() => {
            if (win.closed) {
                clearInterval(interval);
                cb();
            }
        }, 100)
    }

    if (mobile) {
        return (
            <div className="UCATC CPP-left-shadow" style={{ display: 'flex', backgroundColor: 'white' }}>
                <div style={{ width: 16 }} />
                <div className="UCATC-info" style={{ flex: 1, padding: 0, paddingTop: 12 }}>
                    <div>{material} / {modelName} / {generalOption}</div>
                    <div className="UCATC-info-row">
                        <div className="UCATC-info-row-name">Total:</div>
                        <div className="UCATC-info-row-value" style={{ fontWeight: 'bold', fontSize: 23 }}>€{totalAmount}</div>
                    </div>
                    {error && (
                        <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
                    )}
                </div>

                <div style={{ flex: 1, position: 'relative', top: 6 }}>

                    {isLastOfLastSection ? (
                        addingToCart ? (
                            <div className="UCATC-add-to-cart-button">
                                <div className="UCATC-add-to-cart-sub-button">
                                    <CircularProgress size={24} color="inherit" style={{ color: 'white' }} />
                                </div>
                            </div>
                        ) : (
                            <div className="UCATC-add-to-cart-button" style={{ boxShadow: 'none' }}>
                                <div style={{ backgroundColor: 'transparent', color: 'black', flex: 2, display: 'flex' }} className="UCATC-add-to-cart-sub-button" onClick={prevSection}>
                                    <ArrowBackIos style={{ fontSize: 16 }} />
                                    Retour
                                </div>
                                <a style={{ backgroundColor: DARK_GREEN, textAlign: 'center', borderRadius: 16, transform: 'scale(.7)', color: 'white', flex: 3, textDecoration: 'none', boxShadow: 'none' }} className="UCATC-add-to-cart-sub-button" target="_blank" onClick={addToCart} href={`https://monzaya.fr/pages/add-to-cart?fsId=${fsId}`}>
                                    Ajouter au panier
                                </a>
                            </div>
                        )
                    ) : (
                        <div className="UCATC-add-to-cart-button" style={{ opacity: 1, boxShadow: 'none' }}>
                            <div style={{ display: 'flex', backgroundColor: 'transparent', color: 'black' }} className={`UCATC-add-to-cart-sub-button ${selectedSectionIndex == 0 && 'UCATC-add-to-cart-sub-button-hide'}`} onClick={prevSection}>
                                <ArrowBackIos style={{ fontSize: 16 }} />
                                Retour
                            </div>
                            <div className="UCATC-add-to-cart-sub-button" style={{ backgroundColor: 'transparent', color: 'black' }} onClick={nextSection}>
                                <>
                                    Suivant
                                    <ArrowForwardIos style={{ fontSize: 16 }} />
                                </>
                            </div>
                        </div>
                    )}
                </div>

                <AddedToCartDialog />
                <OutOfStockDialog />
            </div>
        )
    }

    return (
        <div className="UCATC CPP-left-shadow">
            <div className="UCATC-info">
                <div>{material} / {modelName} / {generalOption}</div>
                <div className="UCATC-info-row">
                    <div className="UCATC-info-row-name">La base:</div>
                    <div className="UCATC-info-row-value">€{basePrice}</div>
                </div>
                <div className="UCATC-info-row">
                    <div className="UCATC-info-row-name">Quantité:</div>
                    <div className="UCATC-info-row-value">{quantity}</div>
                </div>
                <div className="UCATC-info-row">
                    <div className="UCATC-info-row-name">Total:</div>
                    <div className="UCATC-info-row-value" style={{ fontWeight: 'bold', fontSize: 23 }}>€{totalAmount}</div>
                </div>
                {error && (
                    <div style={{ textAlign: 'center', color: 'red' }}>{error}</div>
                )}
            </div>

            {isLastOfLastSection ? (
                addingToCart ? (
                    <div className="UCATC-add-to-cart-button">
                        <div className="UCATC-add-to-cart-sub-button">
                            <CircularProgress size={24} color="inherit" style={{ color: 'white' }} />
                        </div>
                    </div>
                ) : (
                    <div className="UCATC-add-to-cart-button">
                        <div className="UCATC-add-to-cart-sub-button" style={{ flex: 2, display: isMobile ? 'none' : 'flex' }} onClick={prevSection}>
                            <ArrowBackIos />
                            Retour
                        </div>
                        <a className="UCATC-add-to-cart-sub-button" style={{ flex: 3, textDecoration: 'none' }} target="_blank" onClick={addToCart} href={`https://monzaya.fr/pages/add-to-cart?fsId=${fsId}`}>
                            Ajouter au panier
                        </a>
                    </div>
                )
            ) : (
                <div className="UCATC-add-to-cart-button" style={isMobile ? { opacity: 1 } : {}}>
                    <div className={`UCATC-add-to-cart-sub-button ${selectedSectionIndex == 0 && 'UCATC-add-to-cart-sub-button-hide'}`} style={{ display: isMobile ? 'none' : 'flex' }} onClick={prevSection}>
                        <ArrowBackIos />
                        Retour
                    </div>
                    <div className="UCATC-add-to-cart-sub-button" onClick={isMobile && onCloseSidebar ? onCloseSidebar : nextSection}>
                        {!isMobile ? (
                            <>
                                Suivant
                                <ArrowForwardIos />
                            </>
                        ) : 'Valider'}
                    </div>
                </div>
            )}

            <AddedToCartDialog />
            <OutOfStockDialog />
        </div>
    )
}

export default UserCustomizeAddToCart;
