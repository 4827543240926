import React, { 
    createContext,
    useRef
} from 'react'

interface IValue {
    angle?: number;
    lineSpace?: number;
    charSpacing?: number;
    fontFamily?: string;
    fill?: string;
    fontWeight?: string;
    fontStyle?: string;
    visibility?: Boolean;
};

interface IMainContext {
    setValue?: Function;
    values?: IValue;
    canvasRef?: any;
};

export const MainContext = createContext<IMainContext>({
})

export const MainContextProvider = ({ children }: any) => {
    const [values, setValue] = React.useState<IValue>({
        angle: 0,
        lineSpace: 0,
        charSpacing: 0,
        fontWeight: 'normal',
        fontFamily: 'MrDeHaviland-Regular',
        fill: 'black',
        fontStyle: 'normal',
        visibility: true,
    })
    const canvasRef = useRef<any>(null)

    return (
        <MainContext.Provider value={{
            values,
            setValue,
            canvasRef
        }}>
            {children}
        </MainContext.Provider>
    )
}