import { Box, Breadcrumbs, CircularProgress, List, ListItem, ListItemText } from '@material-ui/core';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Customize, Def } from '../../components/Customize/Customize';
import TextIcon from '../../components/TextIcon/TextIcon';
import { EditProductContext, IUndoRedoType } from '../../contexts/EditProductContext';
import { Section, UserSelectionContext } from '../../contexts/UserSelectionContext';
import ModelsSelection from './ModelsSelection';
import SideSectionHeader from './SideSectionHeader';
import UserCustomizeAddToCart from './UserCustomizeAddToCart';
import UserCustomizeGeneral from './UserCustomizeGeneral';
import UserCustomizeText from './UserCustomizeText';
import * as _ from 'lodash';
import UndoRedo from '../../components/UndoRedo';
import './index.scss';
import ModeleSrc from './../../assets/icons_2k/model-tod.svg';
import GeneralSrc from './../../assets/icons/general.svg';
import ModelsSrc from './../../assets/icons/models.svg';
import ImagesSrc from './../../assets/icons/images.svg';
import { MenuContext } from '../../contexts/MenuContext';
import UserCustomizeImages from './UserCostomizeImages';
import UserCustomizeBottomNav from './UserCustomizeBottomNav';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { WindowResolutionContext } from '../../contexts/WindowResolutionContext';
import { CheckCircleOutlineOutlined } from '@material-ui/icons';
import MobileEtapes from './MobileEtapes';
import { StyleSheet } from '../../const';
import TextModifierSettings from './TextModifierSettings';

document.addEventListener('contextmenu', event => event.preventDefault());

export const ModelPanel = (props) => {
    const {
        mobile = false
    } = props
    const { selectedModelId, isAdmin, models, selectModel, addUndoRedo, product } = useContext(EditProductContext);
    const { isMobile } = useContext(WindowResolutionContext);

    const [selectedCategoryName, setSelectedCategoryName] = useState(null)

    const categoryNames = Object.keys(
        _.countBy(models, s => s.categoryName)
    )//.filter(s => s != '' && s != 'undefined')

    useEffect(() => {
        Object.keys(models)
            .forEach(key => {
                if (key === selectedModelId) {
                    setSelectedCategoryName(`${models[key].categoryName}`)
                }
            })
    }, [models, selectedModelId])

    const onModelSelect = (modelId: string) => {
        addUndoRedo({
            type: IUndoRedoType.ModelChange,
            modelId: selectedModelId
        }, {
            type: IUndoRedoType.ModelChange,
            modelId: modelId
        })
        selectModel(modelId, isAdmin);
    }

    const getModelPreviewImageUrl = (model) => {
        return _.get(model, 'previewImageUrl')
            ? _.get(model, 'previewImageUrl')
            : _.get(model, 'modelImageUrl')
    }

    const filteredModels = _.chain(models)
        .values()
        .filter(s => s.categoryName == (selectedCategoryName == 'undefined' ? null : selectedCategoryName))
        .value()

    const getSortedModels = (models: any[]) => {
        const sortStage1 = _.sortBy(filteredModels, 'modelName');
        for (let i = 0; i < sortStage1.length; i++) {
            for (let j = i + 1; j < sortStage1.length; j++) {
                if (Number(sortStage1[i].modelName.replace(/\D/g, '')) > Number(sortStage1[j].modelName.replace(/\D/g, ''))) {
                    const tmp = JSON.parse(JSON.stringify(sortStage1[i]))
                    sortStage1[i] = JSON.parse(JSON.stringify(sortStage1[j]))
                    sortStage1[j] = JSON.parse(JSON.stringify(tmp))
                }
            }
        }
        return sortStage1
    }

    const style: StyleSheet = {
        mobileCategories: {
            display: 'flex',
            width: '100vw',
            position: 'relative',
            overflowX: 'auto',
        },
        mobileCategory: {
            fontSize: 18,
            fontWeight: 600,
            opacity: .333,
            padding: 6,
            display: 'inline-block'
        },
        selectedCategory: {
            opacity: 1,
            textDecoration: 'underline',
            textUnderlineOffset: 4
        }
    }

    if (mobile) {
        return (
            <div>
                <div className='no-scrollbar' style={style.mobileCategories}>
                    {categoryNames.map(name => (
                        <div
                            onClick={() => setSelectedCategoryName(name)}
                            style={{
                                ...style.mobileCategory,
                                ...(name === selectedCategoryName && style.selectedCategory)
                            }}
                            key={name}
                        >{name}</div>
                    ))}
                </div>

                <div className='no-scrollbar' style={style.mobileCategories}>
                    {
                        getSortedModels(models)
                            .map((model, index) => (
                                <div style={{ margin: 4 }}>
                                    <div className="ModelsSelection-model-container" style={{ position: 'relative', columnGap: 4, height: '100%', width: 178, alignItems: 'center' }} onClick={() => onModelSelect(model.modelId)} key={model.modelId}>
                                        <div className="ModelsSelection-model-image-container">
                                            {/* <div className="ModelsSelection-model-image" style={{ backgroundImage: `url(${getModelPreviewImageUrl(model)})`, height: isMobile ? 80 : 150 }}></div> */}
                                            <div className="ModelsSelection-model-preview-image" style={{ backgroundImage: `url(${getModelPreviewImageUrl(model)})` }}></div>
                                        </div>
                                        <div className="ModelsSelection-model-name" style={{ fontSize: isMobile ? 14 : 18, paddingRight: 12 }}>{model.modelName}</div>
                                        {selectedModelId == model.modelId && <CheckCircleOutlineOutlined style={{ position: 'absolute', top: 1, right: 1 }} color="primary" />}
                                    </div>
                                </div>
                            ))
                    }
                </div>
            </div>
        )
    }

    return (
        <div>
            <div className="ModelsSelection-label" style={{ marginBottom: 4 }}>Thème<span style={{ color: 'red' }}>*</span></div>
            <select style={{
                width: '100%',
                padding: 12,
                borderRadius: 8,
                fontSize: 18
            }} name="theme" id="them" onChange={e => setSelectedCategoryName(e.target.value)}
                value={selectedCategoryName}
            >
                {categoryNames.map(name => <option value={name} key={name}>{name}</option>)}
            </select>

            <div style={{ height: 8 }} />

            <div className="ModelsSelection-label">Sélectionnez le modèle <span style={{ color: 'red' }}>*</span></div>

            {
                getSortedModels(models)
                    .map((model, index) => (
                        <div className="ModelsSelection-model-container" style={{ position: 'relative', columnGap: isMobile ? 2 : 16 }} onClick={() => onModelSelect(model.modelId)} key={model.modelId}>
                            <div className="ModelsSelection-model-image-container" >
                                {/* <div className="ModelsSelection-model-image" style={{ backgroundImage: `url(${getModelPreviewImageUrl(model)})`, height: isMobile ? 80 : 150 }}></div> */}
                                <div className="ModelsSelection-model-preview-image" style={{ backgroundImage: `url(${getModelPreviewImageUrl(model)})` }}></div>
                            </div>
                            <div className="ModelsSelection-model-name" style={{ fontSize: isMobile ? 14 : 18 }}>{model.modelName}</div>
                            {selectedModelId == model.modelId && <CheckCircleOutlineOutlined style={{ position: 'absolute', top: 1, right: 1 }} color="primary" />}
                        </div>
                    ))
            }
        </div>
    )
}

const CustomizeProductPage = () => {
    const { id } = useParams<any>();
    const [text, setText] = useState('change this text');
    const {
        models,
        setProductId,
        product,
        loading,
        selectedBgIndex,
        askHasImage
    } = useContext(EditProductContext);
    const {
        setGeneralOption,
        setMaterial,
        selectedSection,
        setSelectedSection
    } = useContext(UserSelectionContext);
    const { setIsHiddenAdminText } = useContext(MenuContext);
    const generalOptions = _.find(_.get(product, 'options') || [], (d) => d.name !== 'Matériel' && d.name !== 'Modèle');
    const materialOptions = _.find(_.get(product, 'options') || [], (d) => d.name === 'Matériel') || {};

    const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);
    const { isMobile } = useContext(WindowResolutionContext);

    const hasImages = askHasImage();
    const [shouldDisplayLoader, setShouldDisplayLoader] = useState(true)

    useEffect(() => {
        if (!loading) {
            setTimeout(() => {
                setShouldDisplayLoader(false)
            }, 2000)
        }
    }, [loading])

    // useEffect(() => {
    //     const onPageLoad = () => {
    //         alert('loaded')
    //         setShouldDisplayLoader(false);
    //     };

    //     // Check if the page has already loaded
    //     if (document.readyState === "complete") {
    //         onPageLoad();
    //     } else {
    //         window.addEventListener("onload", onPageLoad);
    //         // Remove the event listener when component unmounts
    //         return () => window.removeEventListener("load", onPageLoad);
    //     }
    // }, []);


    const renderSideSectionTitle = () => {
        switch (selectedSection) {
            case Section.MODEL: return <SideSectionHeader isMobile={isMobile} primaryText="Couleur" secondaryText="JE PERSONNALISE" />;
            case Section.TEXT: return <SideSectionHeader isMobile={isMobile} primaryText="Modèle" secondaryText="JE PERSONNALISE" />;
            case Section.GENERAL: return <SideSectionHeader isMobile={isMobile} primaryText="Finale" secondaryText="JE PERSONNALISE" />;
            case Section.IMAGES: return <SideSectionHeader isMobile={isMobile} primaryText="Étape 4" secondaryText="JE PERSONNALISE" />;
        }
        return '';
    }

    const renderSideSectionContent = () => {
        switch (selectedSection) {
            case Section.MODEL: return <ModelsSelection />;
            case Section.TEXT: return <ModelPanel />;
            case Section.GENERAL: return <UserCustomizeGeneral />;
            case Section.IMAGES: return <UserCustomizeImages />
        }
    }

    let screenResizeCount = 25;

    useEffect(() => {
        setProductId(id);
        setIsHiddenAdminText(true);

        let intervalId = setInterval(() => {
            let vh = window.innerHeight * 0.01;
            // Then we set the value in the --vh custom property to the root of the document
            document.documentElement.style.setProperty('--vh', `${vh}px`);

            screenResizeCount--;
            if (screenResizeCount < 0) {
                clearInterval(intervalId);
            }
        }, 100);

        // We listen to the resize event
        // window.addEventListener('resize', () => {
        //     // We execute the same script as before
        //     let vh = window.innerHeight * 0.01;
        //     document.documentElement.style.setProperty('--vh', `${vh}px`);
        // });

        return () => {
            setProductId(null);
        }
    }, []);

    useEffect(() => {
        generalOptions && generalOptions.values && generalOptions.values[0] && setGeneralOption(generalOptions.values[0]);
    }, [generalOptions]);

    useEffect(() => {
        materialOptions['values']
            && setMaterial(materialOptions['values'][selectedBgIndex]);
    }, [selectedBgIndex])

    return (
        <div className="fill-screen" style={{ height: `calc(var(--vh, 1vh) * 100 - ${isMobile ? 43 : 80}px)`, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', overflow: 'hidden' }}>
            {<div style={{ opacity: shouldDisplayLoader ? 1 : 0, transition: 'all .7s', width: '100vw', height: '100vh', position: 'fixed', zIndex: shouldDisplayLoader ? 1000 : -1, top: 0, backgroundColor: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
                <div style={{ marginTop: 12 }} />
                <div>S'il vous plaît, attendez...</div>
            </div>}
            <div style={{ display: 'flex', flex: 1, overflowY: 'hidden' }}>
                <div style={{ backgroundColor: 'rgb(220, 220, 220)' }}>
                    <UserCustomizeText />
                </div>


                <div style={{ flex: 1, position: 'relative', backgroundColor: 'rgb(220, 220, 220)' }}>
                    {isMobile && <TextModifierSettings />}

                    <div style={{ margin: '0 auto', height: isMobile ? 'calc(100% - 43px)' : '100%', position: 'relative', top: isMobile ? 0 : 12 }}>
                        <Customize text={text} setText={setText}></Customize>
                    </div>

                    {/* <div className="mobile">
                        <div style={{ position: 'fixed', bottom: 108, left: 30 }}>
                            <UndoRedo isMobile />
                        </div>
                    </div> */}
                </div>

                <div className="desktop" style={{ height: '100%' }}>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ height: '100%', width: 350, display: 'flex', flexDirection: 'column' }}>
                            <div>{renderSideSectionTitle()}</div>
                            <Box style={{ overflowY: 'scroll', flex: 1 }} className='mid-container'>

                                <div className='mid-mid-container CPP-left-shadow' style={{ position: 'relative', paddingTop: isMobile ? '0 !important' : 20 }}>
                                    {renderSideSectionContent()}
                                </div>
                            </Box>
                            <UserCustomizeAddToCart />
                        </div>

                        <Box className='right-container' style={{ boxShadow: '-1.25px 0px 4px rgba(0, 0, 0, 0.15)' }}>
                            <UndoRedo isMobile={false} />
                            <Box className='choices'>
                                <TextIcon name='Couleur' src={ModelsSrc} onClick={() => setSelectedSection(Section.MODEL)} id={Section.MODEL} selected={selectedSection} />
                                <TextIcon name='Modèle' src={ModeleSrc} onClick={() => setSelectedSection(Section.TEXT)} id={Section.TEXT} selected={selectedSection} />
                                {hasImages && <TextIcon name='Finale' src={ImagesSrc} onClick={() => setSelectedSection(Section.IMAGES)} id={Section.IMAGES} selected={selectedSection} />}
                                <TextIcon name={hasImages ? 'Étape 4' : 'Finale'} src={GeneralSrc} onClick={() => setSelectedSection(Section.GENERAL)} id={Section.GENERAL} selected={selectedSection} />
                            </Box>
                        </Box>
                    </div>
                </div>


            </div>

            {/* <div className="mobile">
                {isMobileSidebarOpen && <div onClick={() => setIsMobileSidebarOpen(false)} style={{ width: '100vw', height: '100vh', position: 'absolute', left: 0, top: 0 }}></div>}
                <div style={{ height: 65 }}>
                    <div style={{ overflowY: 'scroll', transition: 'all .7s', height: 'calc(100% - 65px - 80px)', width: 260, display: 'flex', flexDirection: 'column', position: 'fixed', right: -260, top: 80 }} className={`CPP-android-keyboard-expand ${isMobileSidebarOpen && "CPP-mobile-side-open"}`}>
                        <div style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}>{renderSideSectionTitle()}</div>
                        <Box style={{ overflowY: 'scroll', flex: 1, minHeight: 200 }} className='mid-container'>

                            <div className='mid-mid-container CPP-left-shadow' style={{ position: 'relative', backgroundColor: 'rgba(255, 255, 255, .5)', paddingTop: 6 }}>
                                {renderSideSectionContent()}
                            </div>
                        </Box>
                        <UserCustomizeAddToCart onCloseSidebar={() => setIsMobileSidebarOpen(false)} />
                    </div>

                    <div style={{ position: 'fixed', bottom: 0, left: 0, width: '100vw' }}>
                        <UserCustomizeBottomNav
                            onCloseSidebar={() => setIsMobileSidebarOpen(false)}
                            onOpenSidebar={() => setIsMobileSidebarOpen(true)}
                            isOpenSidebar={isMobileSidebarOpen}
                        />
                    </div>
                </div>
            </div> */}

            <div className="mobile">
                <MobileEtapes
                    selectedSection={selectedSection}
                    setSelectedSection={setSelectedSection}
                />
            </div>
        </div>
    );
}

export default CustomizeProductPage;
