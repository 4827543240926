import React, { useContext, useEffect, useRef, useState } from 'react';
import { EditProductContext } from '../../../contexts/EditProductContext';
import _ from 'lodash';
import './style.scss';
import { ReactComponent as ArrowDownSVG } from './../../../assets/icons/arrow_down.svg';
import ArrowDownPNG from './../../../assets/icons/arrow_down_2k.png';
import { UserSelectionContext } from '../../../contexts/UserSelectionContext';
import { Def, ToImage } from '../../../components/Customize/Customize';
import { fabric } from "fabric"


const UserCustomizeGeneral = (props) => {
    const {
        mobile = false
    } = props
    const {
        product,
        isCanvasLoaded
    } = useContext(EditProductContext);
    const { quantity, setQuantity, setGeneralOption } = useContext(UserSelectionContext);
    const generalOption = _.find(_.get(product, 'options') || [], (d) => d.name !== 'Matériel' && d.name !== 'Modéle');

    const [defaultZoom, setDefaultZoom] = useState(-1)

    const inputElement = useRef(null);

    // useEffect(() => {
    //     if (mobile) {
    //         inputElement.current.onfocus = () => {
    //             window.scrollTo(0, 0);
    //             document.body.scrollTop = 0;
    //         };
    //     }
    // }, [mobile]);

    useEffect(() => {
        if (isCanvasLoaded && defaultZoom === -1) {
            setDefaultZoom(Def.canvas.getZoom())
        }
    }, [isCanvasLoaded])

    const onGeneralOptionChange = (e: any) => {
        setGeneralOption(e.target.value);
    }

    useEffect(() => {
        generalOption && generalOption.values && generalOption.values[0] && setGeneralOption(generalOption.values[0]);
    }, [generalOption, product]);

    // if (!generalOption) {
    //     return <div></div>;
    // }

    const downloadImage = () => {
        const aEl = document.createElement('a')
        Def.canvas.viewportTransform = JSON.parse(localStorage.getItem('viewportTransform'))
        Def.canvas.renderAll()
        aEl.download = 'image.png'
        aEl.href = ToImage()
        aEl.target = '_blank'
        aEl.click()
    }

    const optionsRef = useRef(null)

    const optionsDesktopRef = useRef(null)

    if (mobile) {
        return (
            <div style={{ width: '100%' }}>
                <div className="UserCustomizeGeneral" style={{ display: 'flex', flexDirection: 'row', width: '100%', flex: 1, padding: '0 32px' }}>

                    {generalOption && (
                        <div style={{ flex: 1 }}>
                            <div className="UserCustomizeGeneral-label">{generalOption.name} <span style={{ color: 'red' }}>*</span></div>
                            <div style={{ position: 'relative' }}>
                                <select ref={optionsRef} className="UserCustomizeGeneral-select" onChange={onGeneralOptionChange}>
                                    {generalOption.values.map(value => (
                                        <option value={value} key={value}>{value}</option>
                                    ))}
                                </select>
                                <img onClick={() => {
                                    optionsRef.current?.focus()
                                    optionsRef.current?.click()
                                    console.log('click')
                                }} src={ArrowDownPNG} style={{ position: 'absolute', pointerEvents: 'none', right: 6, top: '50%', transform: 'translateY(-50%)', height: 20 }} />
                                {/* <ArrowDownSVG style={{ position: 'absolute', right: 12, top: '50%', transform: 'translateY(-50%)' }} /> */}
                            </div>
                        </div>
                    )}

                    <div style={{ width: 48 }} />

                    <div>
                        <div className="UserCustomizeGeneral-label">Quantité <span style={{ color: 'red' }}>*</span></div>
                        <div className="UserCustomizeGeneral-quantity">
                            <div className="UserCustomizeGeneral-quantity-sign" onClick={() => setQuantity(Math.max(quantity - 1, 1))}>-</div>
                            <div className="UserCustomizeGeneral-quantity-number">{quantity}</div>
                            <div className="UserCustomizeGeneral-quantity-sign" onClick={() => setQuantity(Math.min(quantity + 1, 99))}>+</div>
                        </div>
                    </div>
                </div>
                <div style={{ height: 16 }} />
                <div onClick={() => downloadImage()} style={{ textAlign: 'center', cursor: 'pointer', color: 'red' }}>Vous pouvez télécharger l'aperçu</div>
                <div style={{ height: 16 }} />
            </div>
        )
    }

    return (
        <div className="UserCustomizeGeneral" style={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%', flex: 1 }}>
            {generalOption && (
                <div style={{ marginBottom: 28, flex: 1 }}>
                    <div className="UserCustomizeGeneral-label">{generalOption.name} <span style={{ color: 'red' }}>*</span></div>
                    <div style={{ position: 'relative' }}>
                        <select ref={optionsDesktopRef} className="UserCustomizeGeneral-select" onChange={onGeneralOptionChange}>
                            {generalOption.values.map(value => (
                                <option value={value} key={value}>{value}</option>
                            ))}
                        </select>
                        <img onClick={() => optionsDesktopRef.current.click()} src={ArrowDownPNG} style={{ position: 'absolute', right: 6, top: '50%', transform: 'translateY(-50%)', height: 20 }} />

                        {/* <ArrowDownSVG style={{ position: 'absolute', right: 12, top: '50%', transform: 'translateY(-50%)' }} /> */}
                    </div>
                </div>
            )}

            <div>
                <div className="UserCustomizeGeneral-label">Quantité <span style={{ color: 'red' }}>*</span></div>
                <div className="UserCustomizeGeneral-quantity">
                    <div className="UserCustomizeGeneral-quantity-sign" onClick={() => setQuantity(Math.max(quantity - 1, 1))}>-</div>
                    <div className="UserCustomizeGeneral-quantity-number">{quantity}</div>
                    <div className="UserCustomizeGeneral-quantity-sign" onClick={() => setQuantity(Math.min(quantity + 1, 99))}>+</div>
                </div>
            </div>

            <div style={{ flex: 1 }} />
            <div onClick={() => downloadImage()} style={{ textAlign: 'center', cursor: 'pointer', color: 'red', position: 'absolute', bottom: 12, left: 0, right: 0 }}>Vous pouvez télécharger l'aperçu</div>
        </div>
    )
}

export default UserCustomizeGeneral;
