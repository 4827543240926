import React, { useState, useEffect } from "react"
import { DataGrid, GridRowId } from "@material-ui/data-grid"
import "./style.scss"
import { useFirestoreStorage } from "../../../firebase/firebase"

const Table = ({ data }) => {
  const [selectedModels, setSelectedModels] = useState<GridRowId[]>([])
  const { getUrl } = useFirestoreStorage(["models"])

  const status = params => {
    return (
      <div
        className="status"
        style={{ backgroundColor: params.value ? "#EDF9F0" : "#EDF9F0" }}
      >
        <p style={{ color: params.value ? "#287D3C" : "red" }}>
          {params.value ? "Actif" : "Deactif"}
        </p>
      </div>
    )
  }

  const SVGimage = params => {
    const [src, setSrc] = useState(null)

    useEffect(() => {
      getUrl(params.value).then(url => setSrc(url))
    }, [])

    return (
      <>
        <img src={src}></img>
      </>
    )
  }

  const columns = [
    {
      field: "id",
      headerName: "ID",
      flex: 0.8,
      cellClassName: "num-id",
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "name",
      headerName: "Nom",
      flex: 1,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "packageID",
      headerName: "Pack",
      flex: 1,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "filename",
      headerName: "Image",
      flex: 1,
      renderCell: SVGimage,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
    {
      field: "status",
      headerName: "Statut",
      flex: 1,
      renderCell: status,
      renderHeader: params => (
        <div className="header">{params.colDef.headerName}</div>
      ),
    },
  ]

  return (
    <div style={{ height: "80vh" }}>
      <DataGrid
        className="model-table"
        rowHeight={100}
        pageSize={4}
        columns={columns}
        rows={data}
        onSelectionModelChange={(newSelection: any) => {
          setSelectedModels(newSelection.selectionModel)
        }}
        selectionModel={selectedModels}
        checkboxSelection
      />
    </div>
  )
}
export default Table
