export const ADMIN_EMAIL = 'admin@monzaya.fr';


export interface StyleSheet {
    [key: string]: React.CSSProperties
}

export const KOOL_GREEN = '#F7FCF8'//'#F2FDFF'
export const DARK_GREEN = '#354530'

export const TEXT_SELECTION_PADDING = 5;